import React, { useEffect, useState } from "react";
import { Grid, Card, useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WaterIcon from "@mui/icons-material/Water";
import { useNavigate } from "react-router-dom";

import farm03 from "../farm03.jpg";
import farm02 from "../farm02.jpg";
import farm04 from "../farm04.jpg";
import logo from "../../sinisana.png";

import "../Neocrab.css";

const Farm = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = "#EDF2F6";
  }, []);
  
  return (
    <>
      <Grid container style={{ backgroundColor: "#ffffff", padding: "15px" }}>
        <Grid item>
          <a onClick={() => navigate(-1)}>
            <IconButton>
              <ArrowBackIosNewIcon />
            </IconButton>
          </a>
        </Grid>
        <Grid item flex={1}>
          <Typography variant="h4">Farm</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"} spacing={2} padding={2}>
        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    ...(mdUp && {
                      backgroundImage: `url(${farm02})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={farm02}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Our farm is situated in Rambungan, Lundu, Sarawak, about
                      one hour's drive from Kuching.
                    </Typography>
                  </Grid>
                </Grid>

                <br />

                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      This is where we cultivate and harvest crabs in a
                      controlled aquatic environment to ensure optimal
                      conditions for their growth and development. This involves
                      providing proper nutrition, managing water quality and
                      temperature, preventing diseases and predators, and
                      monitoring their growth to achieve a high-quality yield.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    ...(mdUp && {
                      backgroundImage: `url(${farm04})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={farm04}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Our crabs are raised in a spacious and nurturing pontoon,
                      where they are carefully tended to until the moment of
                      harvest. This ensures that only the best quality crabs
                      make it to your plate, giving you a delicious and
                      satisfying dining experience. So, rest assured that when
                      you indulge in our succulent crabs, you are enjoying the
                      result of our commitment to excellence and attention to
                      detail.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <br />

        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    ...(mdUp && {
                      backgroundImage: `url(${farm03})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={farm03}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      The innovative technique of crab farming in a pontoon has
                      enabled the sustainable cultivation of these crustaceans
                      while also providing a unique opportunity for coastal
                      communities to generate income.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} textAlign="center" style={{ padding: "20px" }}>
          <a
            href="https://www.sinisana.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              height="auto"
              style={{ width: "15%", minWidth: 125 }}
              alt="Seafood Traceability by Sinisana"
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default Farm;
