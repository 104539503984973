import React, { useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { format } from "date-fns";

import { Chip, useMediaQuery } from "@mui/material";
import hatchery03 from "../Neocrab/hatchery03.jpg";
import crabOrders02 from "../Neocrab/crabOrders02.jpg";
import farmcrab from "../Neocrab/farmcrab.jpg";

import { useNavigate } from "react-router-dom";
import "./NcTimeline.css";

const NcTimeline = (props) => {
  const navigate = useNavigate();
  const timelineBreakpoint = useMediaQuery("(min-width:1170px)");
  const { data, dataPostgres } = props;
  
  return (
    <VerticalTimeline>
      {/* checks if data exists else grab from dataPostgres */}
      {data &&
      data[0] &&
      data[0]["TraceableBOM"] &&
      data[0]["TraceableBOM"][0] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
        "TraceableBOM"
      ] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
        "TraceableBOM"
      ][0] &&
      data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
        "TraceableBOM"
      ][0]["Attributes"] ? (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#3B71B4", color: "#fff" }}
          {...(timelineBreakpoint && {
            date:
              data &&
              data[0] &&
              data[0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"] &&
              JSON.parse(
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"]
              )
                ? format(
                    new Date(
                      JSON.parse(
                        data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                          "TraceableBOM"
                        ][0]["TraceableBOM"][0]["Attributes"]
                      )["Arrival Timestamp"]
                    ),
                    "PP"
                  )
                : dataPostgres &&
                  dataPostgres[0] &&
                  dataPostgres[0]["IncubationRecord"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                    "arrivalTimestamp"
                  ]
                ? format(
                    new Date(
                      dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                        "arrivalTimestamp"
                      ]
                    ),
                    "PP"
                  )
                : // handle the case where neither data nor dataPostgres[0]["IncubationRecord"] exists
                  "",
          })}
        >
          {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
          {!timelineBreakpoint && (
            <h3 className="vertical-timeline-element-title">
              {/* checks if data exists else grab from dataPostgres */}
              {
                data &&
                data[0] &&
                data[0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"] &&
                JSON.parse(
                  data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                    "TraceableBOM"
                  ][0]["TraceableBOM"][0]["Attributes"]
                )
                  ? format(
                      new Date(
                        JSON.parse(
                          data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                            "TraceableBOM"
                          ][0]["TraceableBOM"][0]["Attributes"]
                        )["Arrival Timestamp"]
                      ),
                      "PP"
                    )
                  : dataPostgres &&
                    dataPostgres[0] &&
                    dataPostgres[0]["IncubationRecord"] &&
                    dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                    dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                      "arrivalTimestamp"
                    ]
                  ? format(
                      new Date(
                        dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                          "arrivalTimestamp"
                        ]
                      ),
                      "PP"
                    )
                  : // handle the case where neither data nor dataPostgres[0]["IncubationRecord"] exists
                    ""
              }
            </h3>
          )}

          <div>
            <img
              src={hatchery03}
              height="auto"
              alt="Product"
              style={{ width: "100%" }}
            />
          </div>
          <h3 className="vertical-timeline-element-title">
            Arrival of Broodstock
          </h3>
          {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
          <h4 className="vertical-timeline-element-subtitle">
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0]
              ? data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Location"]
              : "Sematan Hatchery"}
          </h4>
          <p style={{ fontSize: "16px" }}>
            Today we received a broodstock with the species of{" "}
            <b>
              {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
              {data &&
              data[0] &&
              data[0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"] &&
              JSON.parse(
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"]
              )
                ? JSON.parse(
                    data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                      "TraceableBOM"
                    ][0]["TraceableBOM"][0]["Attributes"]
                  )["Species"]
                : dataPostgres[0] &&
                  dataPostgres[0]["IncubationRecord"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                    "species"
                  ]}
            </b>
            {", "} weighing{" "}
            <b>
              {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
              {data &&
              data[0] &&
              data[0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"] &&
              JSON.parse(
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"]
              )
                ? JSON.parse(
                    data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                      "TraceableBOM"
                    ][0]["TraceableBOM"][0]["Attributes"]
                  )["Weight (g)"]
                : dataPostgres[0] &&
                  dataPostgres[0]["IncubationRecord"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                    "weightInGram"
                  ]}
            </b>
            {"g. "}
            The broodstock was transferred to an incubation tank
            <b>
              {" "}
              {dataPostgres &&
              dataPostgres[0] &&
              dataPostgres[0]["IncubationRecord"]
                ? "on " +
                  format(
                    new Date(dataPostgres[0]["IncubationRecord"]["spawnDate"]),
                    "PP"
                  )
                : null}
            </b>{" "}
            when it began spawning,
            {dataPostgres &&
            dataPostgres[0] &&
            dataPostgres[0]["IncubationRecord"]
              ? (
                <>
                  {" and the resulting eggs hatched on "}
                  <b>
                    {format(new Date(dataPostgres[0]["IncubationRecord"]["hatchDate"]), "PP")}
                  </b>
                  {"."}
                </>
              )
              : " until the eggs hatched. "}
            The zoea are monitored until they become crablets and is harvested{" "}
            <b>
              {/* retrieved information for hatchery from postgres */}
              {dataPostgres &&
              dataPostgres[0] &&
              dataPostgres[0]["productionTimestamp"]
                ? (
                  <>
                    {"on "}
                    <b>
                      {format(new Date(dataPostgres[0]["productionTimestamp"]), "PP")}
                    </b>
                  </>
                )
                : null}
            </b>
            {". "}
            {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0]["Attributes"] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"]
            )
              ? " Finally, the broodstock is released back into the wild on " +
                format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "TraceableBOM"
                      ][0]["TraceableBOM"][0]["Attributes"]
                    )["Release Date"]
                  ),
                  "PP"
                ) +
                "."
              : ""}
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <a
              href="aqua/hatchery"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <Chip
                label="Learn More"
                variant="outlined"
                size="medium"
                style={{ border: "1px solid #000", minWidth: "150px" }}
              />
            </a>
          </div>
        </VerticalTimelineElement>
      ) : dataPostgres &&
        dataPostgres[0] &&
        dataPostgres[0]["IncubationRecord"] ? (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#3B71B4", color: "#fff" }}
          {...(timelineBreakpoint && {
            date:
              data &&
              data[0] &&
              data[0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"] &&
              JSON.parse(
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"]
              )
                ? format(
                    new Date(
                      JSON.parse(
                        data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                          "TraceableBOM"
                        ][0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"]
                      )["Arrival Timestamp"]
                    ),
                    "PP"
                  )
                : dataPostgres &&
                  dataPostgres[0] &&
                  dataPostgres[0]["IncubationRecord"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                    "arrivalTimestamp"
                  ]
                ? format(
                    new Date(
                      dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                        "arrivalTimestamp"
                      ]
                    ),
                    "PP"
                  )
                : // handle the case where neither data nor dataPostgres[0]["IncubationRecord"] exists
                  "",
          })}
        >
          {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
          {!timelineBreakpoint && (
            <h3 className="vertical-timeline-element-title">
              {/* checks if data exists else grab from dataPostgres */}
              {
                data &&
                data[0] &&
                data[0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0] &&
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"] &&
                JSON.parse(
                  data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                    "TraceableBOM"
                  ][0]["TraceableBOM"][0]["Attributes"]
                )
                  ? format(
                      new Date(
                        JSON.parse(
                          data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                            "TraceableBOM"
                          ][0]["TraceableBOM"][0]["TraceableBOM"][0][
                            "Attributes"
                          ]
                        )["Arrival Timestamp"]
                      ),
                      "PP"
                    )
                  : dataPostgres &&
                    dataPostgres[0] &&
                    dataPostgres[0]["IncubationRecord"] &&
                    dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                    dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                      "arrivalTimestamp"
                    ]
                  ? format(
                      new Date(
                        dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                          "arrivalTimestamp"
                        ]
                      ),
                      "PP"
                    )
                  : // handle the case where neither data nor dataPostgres[0]["IncubationRecord"] exists
                    ""
              }
            </h3>
          )}

          <div>
            <img
              src={hatchery03}
              height="auto"
              alt="Product"
              style={{ width: "100%" }}
            />
          </div>
          <h3 className="vertical-timeline-element-title">
            Arrival of Broodstock
          </h3>
          {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
          <h4 className="vertical-timeline-element-subtitle">
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0]
              ? data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Location"]
              : "Sematan Hatchery"}
          </h4>
          <p style={{ fontSize: "16px" }}>
            Today we received a broodstock with the species of{" "}
            <b>
              {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
              {data &&
              data[0] &&
              data[0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"] &&
              JSON.parse(
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"]
              )
                ? JSON.parse(
                    data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                      "TraceableBOM"
                    ][0]["TraceableBOM"][0]["Attributes"]
                  )["Species"]
                : dataPostgres[0] &&
                  dataPostgres[0]["IncubationRecord"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                    "species"
                  ]}
            </b>
            {", "} weighing{" "}
            <b>
              {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
              {data &&
              data[0] &&
              data[0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0] &&
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"] &&
              JSON.parse(
                data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["TraceableBOM"][0]["Attributes"]
              )
                ? JSON.parse(
                    data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                      "TraceableBOM"
                    ][0]["TraceableBOM"][0]["Attributes"]
                  )["Weight (g)"]
                : dataPostgres[0] &&
                  dataPostgres[0]["IncubationRecord"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"] &&
                  dataPostgres[0]["IncubationRecord"]["HatcheryReceipt"][
                    "weightInGram"
                  ]}
            </b>
            {"g. "}
            The broodstock was transferred to an incubation tank on{" "}
            <b>
              {" "}
              {dataPostgres &&
              dataPostgres[0] &&
              dataPostgres[0]["IncubationRecord"]
                ? format(
                    new Date(dataPostgres[0]["IncubationRecord"]["spawnDate"]),
                    "PP"
                  )
                : null}
            </b>{" "}
            when it began spawning, and the resulting eggs hatched on{" "}
            <b>
              {dataPostgres &&
              dataPostgres[0] &&
              dataPostgres[0]["IncubationRecord"]
                ? format(
                    new Date(dataPostgres[0]["IncubationRecord"]["hatchDate"]),
                    "PP"
                  )
                : null}
              {". "}
            </b>
            The zoea are monitored until they become crablets and is harvested
            on{" "}
            <b>
              {/* retrieved information for hatchery from postgres */}
              {dataPostgres &&
              dataPostgres[0] &&
              dataPostgres[0]["productionTimestamp"]
                ? format(new Date(dataPostgres[0]["productionTimestamp"]), "PP")
                : null}
            </b>
            {". "}
            {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "TraceableBOM"
            ][0]["Attributes"] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "TraceableBOM"
              ][0]["Attributes"]
            )
              ? " Finally, the broodstock is released back into the wild on " +
                format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "TraceableBOM"
                      ][0]["TraceableBOM"][0]["Attributes"]
                    )["Release Date"]
                  ),
                  "PP"
                ) +
                "."
              : ""}
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <a
              href="aqua/hatchery"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <Chip
                label="Learn More"
                variant="outlined"
                size="medium"
                style={{ border: "1px solid #000", minWidth: "150px" }}
              />
            </a>
          </div>
        </VerticalTimelineElement>
      ) : // handle the case where neither data nor dataPostgres[0]["IncubationRecord"] exists
      null}

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        {...(timelineBreakpoint && {
          date:
            data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "Attributes"
            ] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "Attributes"
              ]
            )
              ? format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "TraceableBOM"
                      ][0]["Attributes"]
                    )["Harvest Date"]
                  ),
                  "PP"
                )
              : dataPostgres &&
                dataPostgres["DeliveryOrderRecord"] &&
                dataPostgres["DeliveryOrderRecord"][0] &&
                dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"]
              ? format(
                  new Date(
                    dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"][
                      "FarmReceipt"
                    ]["arrivalTimestamp"]
                  ),
                  "PP"
                )
              : "",
        })}
        iconStyle={{ background: "#3B71B4", color: "#fff" }}
      >
        {!timelineBreakpoint && (
          <h3 className="vertical-timeline-element-title">
            {/* retrieved information from fluree for hatchery. checks if hatchery information exists or not */}
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
              "Attributes"
            ] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0][
                "Attributes"
              ]
            )
              ? format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "TraceableBOM"
                      ][0]["Attributes"]
                    )["Harvest Date"]
                  ),
                  "PP"
                )
              : dataPostgres &&
                dataPostgres["DeliveryOrderRecord"] &&
                dataPostgres["DeliveryOrderRecord"][0] &&
                dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"]
              ? format(
                  new Date(
                    dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"][
                      "FarmReceipt"
                    ]["arrivalTimestamp"]
                  ),
                  "PP"
                )
              : null}
          </h3>
        )}
        <div>
          <img
            src={farmcrab}
            height="auto"
            alt="Product"
            style={{ width: "100%" }}
          />
        </div>
        <h3 className="vertical-timeline-element-title">
          Crablet Farming & Harvesting
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          {
            data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0]
              ? data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["Location"]
              : dataPostgres &&
                dataPostgres["DeliveryOrderRecord"] &&
                dataPostgres["DeliveryOrderRecord"][0] &&
                dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"] &&
                dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"][
                  "FarmReceipt"
                ] &&
                dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"][
                  "FarmReceipt"
                ]["location"]
              ? dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"][
                  "FarmReceipt"
                ]["location"]
              : ""
          }
        </h4>
        <p style={{ fontSize: "16px" }}>
          Crablets arrived from hatchery and was placed in the nursery, where
          they are fed until they are ready to be harvested and stocked into
          ponds. Workers then regularly check and adjust the pond cycle by
          adding feedings, treatments, and monitoring parameters, until the
          crablets reached maturity. Once it reached maturity, the crabs are
          harvested on{" "}
          <b>
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"]
            )
              ? format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "Attributes"
                      ]
                    )["Harvest Date"]
                  ),
                  "PP"
                )
              : format(
                  new Date(
                    dataPostgres["DeliveryOrderRecord"][0]["SSCHarvestDaily"][
                      "harvestDate"
                    ]
                  ),
                  "PP"
                )}{" "}
          </b>
          from the pond and prepare them for delivery to the processing center.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <a
            onClick={() => {
              // pass postgres data to processing page
              navigate("/aqua/farm", {
                state: {
                  dataPostgres: dataPostgres,
                },
              });
            }}
            style={{
              textDecoration: "none",
              color: "black",
              cursor: "pointer",
            }}
          >
            <Chip
              label="Learn More"
              variant="outlined"
              size="medium"
              style={{ border: "1px solid #000", minWidth: "150px" }}
            />
          </a>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        {...(timelineBreakpoint && {
          date:
            data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"]
            )
              ? format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "Attributes"
                      ]
                    )["Harvest Date"]
                  ),
                  "PP"
                )
              : format(
                  new Date(
                    dataPostgres["DeliveryOrderRecord"][0]["departTimestamp"]
                  ),
                  "PP"
                ),
          // JSON.parse(data[0]["Attributes"])
          //   ? format(
          //       new Date(JSON.parse(data[0]["Attributes"])["Pack Date"]),
          //       "PP"
          //     )
          //   : null,
        })}
        iconStyle={{ background: "#3B71B4", color: "#fff" }}
      >
        {!timelineBreakpoint && (
          <h3 className="vertical-timeline-element-title">
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"] &&
            JSON.parse(
              data[0]["TraceableBOM"][0]["TraceableBOM"][0]["Attributes"]
            )
              ? format(
                  new Date(
                    JSON.parse(
                      data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                        "Attributes"
                      ]
                    )["Harvest Date"]
                  ),
                  "PP"
                )
              : format(
                  new Date(
                    dataPostgres["DeliveryOrderRecord"][0]["departTimestamp"]
                  ),
                  "PP"
                )}
          </h3>
        )}
        <div>
          <img
            src={crabOrders02}
            height="auto"
            alt="Product"
            style={{ width: "100%" }}
          />
        </div>

        <h3 className="vertical-timeline-element-title">Processing</h3>
        <h4 className="vertical-timeline-element-subtitle">
          {data[0]["Location"] ? data[0]["Location"] : "Bako Processing Center"}
        </h4>
        <p style={{ fontSize: "16px" }}>
          The crabs are received on this day from{" "}
          <b>
            {data &&
            data[0] &&
            data[0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"] &&
            data[0]["TraceableBOM"][0]["TraceableBOM"][0]["TraceableBOM"][0]
              ? data[0]["TraceableBOM"][0]["TraceableBOM"][0][
                  "TraceableBOM"
                ][0]["Location"] + ". "
              : dataPostgres &&
                dataPostgres["DeliveryOrderRecord"] &&
                dataPostgres["DeliveryOrderRecord"][0]["sourceFarmId"] == "1"
              ? "Telaga Air Farm. "
              : "Rambungan Farm. "}
          </b>
          Each crab is meticulously packed and labeled with care, ensuring that
          it is easily identifiable with its unique batch number before
          undergoing blast freezing process which freezes them quickly at
          extremely low temperatures of{" "}
          <b>
            {dataPostgres
              ? dataPostgres["FactoryFreezingRecord"][0]["freezerTemperature"] +
                "°C. "
              : "-19°C"}
          </b>
          After the process, they are carefully packed into a box which you are
          now seeing, and that's where the QR code comes into play.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <a
            onClick={() => {
              // pass postgres data to processing page
              navigate("/aqua/processing", {
                state: {
                  dataPostgres: dataPostgres,
                },
              });
            }}
            style={{
              textDecoration: "none",
              color: "black",
              cursor: "pointer",
            }}
          >
            <Chip
              label="Learn More"
              variant="outlined"
              size="medium"
              style={{ border: "1px solid #000", minWidth: "150px" }}
            />
          </a>
        </div>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};

export default NcTimeline;
