import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useNavigate, useParams } from "react-router-dom";

import sscDish from "../sscDish.jpg";
import softShellCrabs from "../soft-shell-crabs.jpg";
import logo from "../../sinisana.png";

const Recipes = () => {
  const serverUrl = "https://neocrab.sinisana.net/api/web-1228";
  
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const params = useParams();
  
  useEffect(() => {
    document.body.style.backgroundColor = "#EDF2F6";
  }, []);

  useEffect(() => {
    fetch(serverUrl + "/recipes/" + params.recipeId, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === "success") {
          if (res.data) {
            setData(res.data);
          }
        }
      });
  }, []);
  
  return (
    <>
      <Grid
        container
        style={{ backgroundColor: "#ffffff", padding: "15px" }}
      >
        <Grid item>
          <a onClick={() => navigate(-1)}>
            <IconButton>
              <ArrowBackIosNewIcon />
            </IconButton>
          </a>
        </Grid>

        <Grid item flex={1}>
          <Typography variant="h4">Recipes</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={data.id == 2 ? sscDish : softShellCrabs}
              height="auto"
              width="80%"
              alt="Product"
              style={{ maxWidth: "100%", borderRadius: "10%" }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingTop: "20px",
            marginTop: "20px",
            backgroundColor: "#ffffff",
          }}
        >
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {data?.title}
          </Typography>

          <Grid item xs={12}>
            <Box style={{ padding: "20px" }}>{data?.shortDescription}</Box>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ padding: "20px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.content,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} textAlign="center" style={{ padding: "20px" }}>
          <a
            href="https://www.sinisana.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              height="auto"
              style={{ width: "15%", minWidth: 125 }}
              alt="Seafood Traceability by Sinisana"
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default Recipes;
