import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import BackgroundImage1 from "./assets/bg-1.jpg";
import BackgroundImage2 from "./assets/bg-2.jpg";
import BackgroundImage3 from "./assets/bg-3.jpg";
import BackgroundImage4 from "./assets/bg-4.jpg";
// import BackgroundImage5 from "./assets/bg-5.jpg";
// import BackgroundImage6 from "./assets/bg-6.jpg";

import ScanResult from "./components/ScanResult";
import NotFound from "./components/NotFound";

import { Typography } from "@mui/material";

const BackgroundArray = [BackgroundImage1, BackgroundImage2, BackgroundImage3];

const backendUrl = "https://origo.demos.sinisana.io/api/_1125";
// const backendUrl = "http://localhost:4000/_1125";

const data = {
  _id: 387028092978665,
  "StockTransfer/_Products": [
    {
      _id: 369435906932767,
      TraceableDestination: {
        Name: "Durban Fast Logistics",
        _id: 439804651155147,
        Location: '{"Name":"Port of Durban, South Africa","Latitude":"26.869","Longitude":"31.045"}',
      },
      TraceableOrigin: {
        Name: "SwiftHive Logistics",
        _id: 439804651155139,
        Location: '{"Name":"Hai Phong Port, Vietnam","Latitude":"20.8465","Longitude":"106.6889"}',
      },
      StockTransferDate: "2023-05-22T08:01:54.136Z",
    },
    {
      _id: 369435906932766,
      TraceableDestination: {
        Name: "SwiftHive Logistics",
        _id: 439804651155139,
        Location: '{"Name":"Hai Phong Port, Vietnam","Latitude":"20.8465","Longitude":"106.6889"}',
      },
      TraceableOrigin: {
        Name: "Origo",
        _id: 439804651155059,
        Location: '{"Name":"Penang","Latitude":"5.4164","Longitude":"100.3327"}',
      },
      StockTransferDate: "2023-05-22T07:26:07.741Z",
    },
  ],
  "Product/Collaborators": [
    {
      _id: 439804651155059,
    },
    {
      _id: 439804651155139,
    },
    {
      _id: 439804651155147,
    },
  ],
  "Product/Status": "New",
  "Product/TraceableBOM": [
    {
      _id: 387028092978408,
    },
  ],
  "Product/Attributes":
    '{"productionDate":"2023-04-19","rawMaterial":"Rice Husk","dimension":"48\\" x 40\\"","loadCapacity":"3500 lbs","totalRawQtyUsed":[12]}',
  "Product/Types": ["Origo Pallet"],
  "Product/TraceCodes": ["https://trace.sinisana.io/origo?a=8JefKnkSThMpLc-1Q9Yjx"],
  "Product/Name": 'Pallet 48" x 40"',
  CurrentOwner: {
    Name: "Durban Fast Logistics",
    _id: 439804651155147,
    Location: '{"Name":"Port of Durban, South Africa","Latitude":"26.869","Longitude":"31.045"}',
  },
};

const OrigoTrace = () => {
  const traceCode = window.location.href;

  const [searching, setSearching] = useState(true);
  const [result, setResult] = useState(null);
  const [historyResult, setHistoryResult] = useState([]);

  useEffect(() => {
    const random = Math.floor(Math.random() * 3);
    document.body.style.backgroundImage = `url(${BackgroundArray[random]})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundPosition = "center";
  }, []);

  // useEffect(() => {
  //   setSearching(false);
  //   setResult(data);
  // }, []);

  useEffect(() => {
    fetch(`${backendUrl}/trace`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        traceCode: traceCode,
        // traceCode: "https://trace.sinisana.io/origo?a=PWeFdWX1MHJox4Ya_FEdb",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success" && res.data) {
          setResult(res.data);
        } else {
          setResult(null);
        }
      })
      .finally(() => {
        setSearching(false);
      });
  }, [traceCode]);

  useEffect(() => {
    if (result && result._id) {
      fetch(`${backendUrl}/trace/findPalletOwnerHistory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          palletId: result._id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success" && res.data) {
            let array = [...res.data];

            array.sort((a, b) => Number(b.Block) - Number(a.Block));

            setHistoryResult(array);
          } else {
            setHistoryResult(null);
          }
        });
    }
  }, [result]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        {searching ? (
          <Box
            sx={{
              display: "flex",
              marginTop: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : result ? (
          <ScanResult result={result} historyResult={historyResult} />
        ) : (
          <NotFound />
        )}
      </Container>
    </Box>
  );
};

export default OrigoTrace;
