import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const NotFound = () => {
  return (
    <Stack paddingTop={8}>
      <Card style={{ backgroundColor: "#FAF2DA" }}>
        <CardContent>
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Typography gutterBottom variant="overline" color="#395144" fontSize={20} fontWeight="bold">
              Product Not Found
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default NotFound;
