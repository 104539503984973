import React from "react";
import BurgerView from "./components/Content/BurgerView";
import BurgerSearch from "./components/Content/BurgerSearch";
import DesktopView from "./components/Content/DesktopView";
import TraceMeBackView from "./components/Content/TraceMeBackView";
import HalalCertViewer from "./components/Content/HalalCertViewer";
import LitSaraSearch from "./components/Content/LitSara/LitSaraSearch";
import LitSaraSearchTC from "./components/Content/LitSara/LitSaraSearchTC";
import Recipes from "./components/Content/Neocrab/components/Recipes";
import Hatchery from "./components/Content/Neocrab/components/Hatchery";
import Farm from "./components/Content/Neocrab/components/Farm";
import Processing from "./components/Content/Neocrab/components/Processing";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import LitSaraSearchTCV2 from "./components/Content/LitSara/LitSaraSearchTCV2";
import LitSaraTrace from "./components/Content/LitSara/LitSaraTrace";
import OrigoTrace from "./components/Content/Origo/OrigoTrace";
import AlmaraiResult from "./components/Content/Almarai/AlmaraiResult";
import OtentikTrace from "./components/Content/Autentik/OtentikTrace";

// const App = () => <DesktopView />;
// const App = () => <BurgerView />;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/aqua/*" element={<DesktopView />} />
        <Route path="/aqua/recipes/:recipeId" element={<Recipes />} />
        <Route path="/aqua/hatchery" element={<Hatchery />} />
        <Route path="/aqua/farm" element={<Farm />} />
        <Route path="/aqua/processing" element={<Processing />} />
        <Route path="/halal/search" element={<BurgerSearch />} />
        <Route path="/halal/burger" element={<BurgerView />} />
        <Route path="/halal/burger/traceMeBack" element={<TraceMeBackView />} />
        <Route path="/halal/burger/halalcert" element={<HalalCertViewer />} />
        <Route path="/litsara" element={<LitSaraTrace />} />
        <Route path="/litsara/search" element={<LitSaraSearch />} />
        <Route path="/litsara/searchTC" element={<LitSaraSearchTC />} />
        <Route path="/litsara/searchTCV2" element={<LitSaraSearchTCV2 />} />
        <Route path="/origo" element={<OrigoTrace />} />
        <Route path="/almarai" element={<AlmaraiResult />} />
        <Route path="/otentik" element={<OtentikTrace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
