import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import LitSaraLogo from "./LitSaraLogoHorizontal.png";
import BackgroundImage1 from "./assets/bg-1.jpg";
import BackgroundImage2 from "./assets/bg-2.jpg";
import BackgroundImage3 from "./assets/bg-3.jpg";
import BackgroundImage4 from "./assets/bg-4.jpg";
import BackgroundImage5 from "./assets/bg-5.jpg";
import BackgroundImage6 from "./assets/bg-6.jpg";
import Search from "./components/Search";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoDialog from "./components/InfoDialog";
import "./Litsara.css";

const backendUrl = "https://sbc.demos.sinisana.io/api/_1125";
// const backendUrl = "https://abs.sinisana.net/api/_1125";
// const backendUrl = "http://localhost:4000/_1125";

const BackgroundArray = [BackgroundImage1, BackgroundImage2, BackgroundImage3, BackgroundImage4, BackgroundImage5, BackgroundImage6];

const LitSaraSearchTCV2 = () => {
  const [data, setData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedPackaging, setSelectedPackaging] = useState("");
  const [selectedBatchNo, setSelectedBatchNo] = useState("");
  const [result, setResult] = useState({});

  const [editItem, setEditItem] = useState({});
  const [infoDialog, setInfoDialog] = useState(false);

  useEffect(() => {
    const random = Math.floor(Math.random() * 5);
    document.body.style.backgroundImage = `url(${BackgroundArray[random]})`;
    document.body.style.backgroundSize = "cover";
    // document.body.style.backgroundImage = "linear-gradient(to bottom right, #edf6f9, #83c5be)";
  }, []);

  useEffect(() => {
    // Obtain product options from Backend
    fetch(`${backendUrl}/options?type=Litsara Product`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === "success") {
          let object = {};

          for (const item of res.data) {
            if (object.hasOwnProperty(item.attributes["Product"])) {
              const product = item.attributes["Product"];

              if (!object[product].hasOwnProperty(item.attributes["Packaging"])) {
                object[product][item.attributes["Packaging"]] = [item.attributes["Image URL"]];
              }
            } else {
              object[item.attributes["Product"]] = {
                [item.attributes["Packaging"]]: item.attributes["Image URL"],
              };
            }
          }

          setData(object);
        }
      });
  }, []);

  const handleChangeProduct = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleChangePackaging = (event) => {
    setSelectedPackaging(event.target.value);
  };

  const handleChangeBatchNo = (event) => {
    setSelectedBatchNo(event.target.value);
  };

  // Framework Code variable
  const [frameworkResult, setFrameworkResult] = useState({});
  // product info
  const [productInfoName, setProductInfoName] = useState("");
  const [productInfoData, setProductInfoData] = useState([]);
  const [productInfoImage, setProductInfoImage] = useState({});
  const [productInfoDescription, setProductInfoDescription] = useState({});
  // social impact
  const [socialImpactTitle, setSocialImpactTitle] = useState("");
  const [socialImpactData, setSocialImpactData] = useState({});
  const [socialImpactImage, setSocialImpactImage] = useState({});
  // product journey
  const [productJourneyTitle, setProductJourneyTitle] = useState("");
  const [productJourneyData, setProductJourneyData] = useState([]);

  const handleSubmit = () => {
    const params = {
      product: selectedProduct,
      packaging: selectedPackaging,
      batchNo: selectedBatchNo,
    };

    fetch(`${backendUrl}/traceTCFramework`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === "success") {
          if (res.data) {
            setResult(res.data.dataResult);
            setFrameworkResult(res.data.storyContent);
          } else {
            setResult({
              Product: "Not Found",
            });
          }
        } else {
          setResult({
            Product: "Not Found",
          });
        }
      });
  };

  // Framework Code
  useEffect(() => {
    if (frameworkResult) {
      if (frameworkResult.productInfoData) {
        let liveData = frameworkResult.productInfoData.productInfoLiveData;
        const checkedRows = liveData.filter((row) => row.checkbox);

        setProductInfoData(checkedRows);
        setProductInfoName(frameworkResult.productInfoData.productName);
        setProductInfoImage(frameworkResult.productInfoData.productInfoImage[0]);
        setProductInfoDescription(frameworkResult.productInfoData.productInfoDescription);
      }
      if (frameworkResult.socialImpactData) {
        setSocialImpactTitle(frameworkResult.socialImpactData.socialImpactTitle);
        setSocialImpactData(frameworkResult.socialImpactData.socialImpactDescription);
        setSocialImpactImage(frameworkResult.socialImpactData.socialImapctImage[0]);
      }
      if (frameworkResult.productJourneyData) {
        setProductJourneyTitle(frameworkResult.productJourneyData.productJourneyTitle);
        setProductJourneyData(frameworkResult.productJourneyData.productJourneyDescription);
      }
    }
  }, [frameworkResult]);

  const handleScrollClickSocialImpact = () => {
    const titleElement = document.getElementById("socialImpact");
    if (titleElement) {
      window.scrollTo({
        top: titleElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleScrollClickProductJourney = () => {
    const titleElement = document.getElementById("productJourney");
    if (titleElement) {
      window.scrollTo({
        top: titleElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 70;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        {result && result.Product && result.Product === "Not Found" ? (
          <Stack paddingTop={8}>
            <Card style={{ backgroundColor: "#FAF2DA" }}>
              <CardContent>
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <Typography gutterBottom variant="overline" color="#395144" fontSize={20} fontWeight="bold">
                    Product Not Found
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      bgcolor: "#1C6758",
                      textAlign: "center",
                      color: "#fffde7",
                      ":hover": {
                        bgcolor: "#125447",
                      },
                    }}
                    onClick={() => {
                      setResult({});
                    }}
                  >
                    Search Again
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        ) : JSON.stringify(result) !== "{}" ? (
          <Stack spacing={2} paddingTop={4} paddingBottom={4}>
            <Card>
              <Box sx={{ textAlign: "center" }} pt={2}>
                <img src={LitSaraLogo} height={"50px"} width="auto" alt="Traceability by Sinisana" />
              </Box>
              <CardMedia
                component="img"
                height="220px"
                src={data[selectedProduct][selectedPackaging]}
                alt={selectedProduct ? selectedProduct : "Product Image"}
                sx={{
                  objectFit: "contain",
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color="#473C33" fontWeight={500}>
                  {productInfoName}
                </Typography>

                {productInfoData.map((row) => (
                  <div key={row.id} style={{ display: "flex", alignItems: "center" }}>
                    <Typography gutterBottom color="#483434" variant="subtitle2">
                      {row.textfield}
                    </Typography>
                    <Typography gutterBottom color="#483434" variant="subtitle2" fontWeight="bold">
                      : {row.text}
                    </Typography>
                  </div>
                ))}

                <Box my={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
                  <CardMedia
                    sx={{ height: 200, objectFit: "cover" }}
                    image={backendUrl + "/storages/" + productInfoImage.Key}
                    title="Product Info Image"
                  />
                </Box>

                <div>
                  {productInfoDescription &&
                    productInfoDescription.blocks &&
                    productInfoDescription.blocks?.map((block, index) => {
                      let element;
                      const { text, level } = block.data;
                      const alignment = block.tunes?.anyTuneName?.alignment;
                      const variantMapping = {
                        1: "h3",
                        2: "h4",
                        3: "h5",
                        4: "h6",
                      };
                      const variant = variantMapping[level] || "h3";

                      if (block.type === "header") {
                        element = (
                          <Typography key={index} color="#483434" variant={variant} gutterBottom align={alignment} fontWeight={"bold"}>
                            {text}
                          </Typography>
                        );
                      } else if (block.type === "paragraph") {
                        element = (
                          <Typography
                            key={index}
                            color="#483434"
                            variant="subtitle2"
                            gutterBottom
                            align={alignment}
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        );
                      } else {
                        element = null;
                      }
                      return element;
                    })}
                </div>

                <Stack spacing={2} direction={"row"} pt={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleScrollClickSocialImpact}
                    sx={{
                      color: "#224B0C",
                      borderColor: "#224B0C",
                      width: "50%",
                    }}
                  >
                    See Your Impact
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleScrollClickProductJourney}
                    sx={{
                      color: "#224B0C",
                      borderColor: "#224B0C",
                      width: "50%",
                    }}
                  >
                    Product Journey
                  </Button>
                </Stack>

                <Box pt={1.5}>
                  <Link href="https://www.litsara.com/index.php" underline="hover" color="#224B0C" variant="subtitle2">
                    LitSara®: Borneo's Natural Personal Care - Home
                  </Link>
                </Box>
              </CardContent>
            </Card>

            <div class="reveal" id="socialImpact">
              <Card style={{ backgroundColor: "#FAF2DA" }}>
                <CardContent>
                  <Typography gutterBottom variant="overline" color="#395144" fontSize={20} fontWeight="bold">
                    {socialImpactTitle}
                  </Typography>
                  <div class="reveal">
                    <Box mb={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
                      <CardMedia
                        sx={{ height: 200, objectFit: "cover" }}
                        image={backendUrl + "/storages/" + socialImpactImage.Key}
                        title="Social Impact Image"
                      />
                    </Box>
                  </div>

                  <div>
                    {socialImpactData &&
                      socialImpactData.blocks &&
                      socialImpactData.blocks?.map((block, index) => {
                        let element;
                        const { text, level } = block.data;
                        const alignment = block.tunes?.anyTuneName?.alignment;
                        const variantMapping = {
                          1: "h3",
                          2: "h4",
                          3: "h5",
                          4: "h6",
                        };
                        const variant = variantMapping[level] || "h3";

                        if (block.type === "header") {
                          element = (
                            <div class="reveal">
                              <Typography key={index} color="#483434" variant={variant} align={alignment} fontWeight={"bold"}>
                                {text}
                              </Typography>
                            </div>
                          );
                        } else if (block.type === "paragraph") {
                          element = (
                            <div class="reveal">
                              <Typography
                                key={index}
                                gutterBottom
                                color="#395144"
                                variant="body2"
                                align={alignment}
                                dangerouslySetInnerHTML={{ __html: text }}
                              />
                            </div>
                          );
                        } else {
                          element = null;
                        }
                        return element;
                      })}
                  </div>
                </CardContent>
              </Card>
            </div>

            <div class="reveal" id="productJourney">
              <Card style={{ backgroundColor: "#473C33" }}>
                <CardContent>
                  <Typography gutterBottom variant="overline" color="#FAF2DA" fontSize={20} fontWeight="bold" textDecoration="underline">
                    {productJourneyTitle}
                  </Typography>
                  <Stepper orientation="vertical">
                    {productJourneyData.length > 0 &&
                      productJourneyData.map((item, index) => {
                        return (
                          <Step key={index} active>
                            <div className="reveal">
                              <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                                <CardContent>
                                  <StepLabel
                                    StepIconProps={{
                                      style: {
                                        color: "#064420",
                                      },
                                    }}
                                  >
                                    {item.title ? item.title : ""}
                                  </StepLabel>
                                  <StepContent>
                                    <Stack direction="row" justifyContent="space-between">
                                      <Stack direction="column">
                                        {item.summary.blocks?.map((block, blockIndex) => (
                                          <Typography
                                            key={blockIndex}
                                            color="#064420"
                                            variant="body2"
                                            style={{ textAlign: block.tunes.anyTuneName.alignment }}
                                            gutterBottom
                                          >
                                            {block.data.text}
                                          </Typography>
                                        ))}
                                      </Stack>
                                      <IconButton
                                        onClick={() => {
                                          setEditItem(item);
                                          setInfoDialog(true);
                                          // setHarvestInfoDialog(true);
                                        }}
                                      >
                                        <ArrowForwardIosIcon fontSize="small" />
                                      </IconButton>
                                    </Stack>
                                  </StepContent>
                                </CardContent>
                              </Card>
                            </div>
                          </Step>
                        );
                      })}
                  </Stepper>
                </CardContent>
              </Card>
            </div>

            <Button
              variant="contained"
              style={{ marginTop: "40px" }}
              fullWidth
              sx={{
                bgcolor: "#1C6758",
                textAlign: "center",
                color: "#fffde7",
                ":hover": {
                  bgcolor: "#125447",
                },
              }}
              onClick={() => {
                setResult({});
              }}
            >
              Search Again
            </Button>
          </Stack>
        ) : (
          <Search
            data={data}
            selectedProduct={selectedProduct}
            selectedPackaging={selectedPackaging}
            selectedBatchNo={selectedBatchNo}
            handleChangeProduct={handleChangeProduct}
            handleChangePackaging={handleChangePackaging}
            handleChangeBatchNo={handleChangeBatchNo}
            handleSubmit={handleSubmit}
          />
        )}
      </Container>

      <InfoDialog item={editItem} visible={infoDialog} onClose={() => setInfoDialog(false)} />
    </Box>
  );
};

export default LitSaraSearchTCV2;
