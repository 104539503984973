import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import OrigoLogoBlack from "../../assets/origoLogoBlack.png";
import Pallet from "../../assets/Pallet.png";

import Timeline from "../TimelineComponents/Timeline";

import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "./Map.css";

const Marker = ({ children }) => children;

const options = {
  radius: 75,
  maxZoom: 20,
  reduce: (acc, props) => {
    acc.value += props.value;
    return acc;
  },
};

// const palletLocation = [
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155059, category: "Manufacturer", value: 1374 },
//     geometry: { type: "Point", coordinates: [100.3327, 5.4164] },
//   },
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155070, category: "Logistics", value: 48 },
//     geometry: { type: "Point", coordinates: [121.4737, 31.2304] },
//   },
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155139, category: "Logistics", value: 48 },
//     geometry: { type: "Point", coordinates: [106.6889, 20.8465] },
//   },
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155145, category: "Composter", value: 9 },
//     geometry: { type: "Point", coordinates: [151.2093, -33.8688] },
//   },
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155147, category: "Logistics", value: 3 },
//     geometry: { type: "Point", coordinates: [31.045, 26.869] },
//   },
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155155, category: "Composter", value: 4 },
//     geometry: { type: "Point", coordinates: [0.1278, 51.5074] },
//   },
// ];

// const riceHuskLocation = [
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155141, category: "Producer", value: 4 },
//     geometry: { type: "Point", coordinates: [115.4793, 3.735] },
//   },
//   {
//     type: "Feature",
//     properties: { cluster: false, entityId: 439804651155143, category: "Producer", value: 1 },
//     geometry: { type: "Point", coordinates: [100.4172, 0.9477] },
//   },
// ];

// const { clusters: clusters2, supercluster: supercluster2 } = useSupercluster({
//   points: riceHuskLocation,
//   bounds,
//   zoom,
//   options,
// });

const ProductInfo = (props) => {
  const { result, historyResult } = props;

  const mapRef = useRef();

  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);

  const [data, setData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [location, setLocation] = useState([]);

  // const { clusters, supercluster } = useSupercluster({
  //   points: palletLocation,
  //   bounds,
  //   zoom,
  //   options,
  // });

  // useEffect(() => {
  //   if (result) {
  //     setLocation(result["StockTransfer/_Products"]);
  //     setCurrentLocation(JSON.parse(result["CurrentOwner"]["Location"]));
  //   }
  // }, [result]);

  useEffect(() => {
    if (result) {
      setLocation(result["StockTransfer/_Products"]);
      // console.log(JSON.parse(result["CurrentOwner"]["Entity/Location"]));
      setCurrentLocation(JSON.parse(result["CurrentOwner"]["Entity/Location"]));
      // // Extract the "StockTransfer/_Products" array from your data
      // const stockTransfers = result["StockTransfer/_Products"];

      // // Extract the CurrentOwner information
      // const currentOwner = result.CurrentOwner;
      // const currentOwnerLocation = JSON.parse(result.CurrentOwner.Location);

      // Create an array in the desired format
      // const formattedStockTransfers = stockTransfers.map((transfer) => {
      //   const stockTransferTimestamp = new Date(transfer.StockTransferDate).getTime();
      //   const coordinates = JSON.parse(transfer.TraceableOrigin.Location);
      //   return {
      //     properties: {
      //       Name: transfer.TraceableOrigin.Name,
      //       entityId: transfer.TraceableOrigin._id,
      //       StockTransferTimestamp: stockTransferTimestamp,
      //     },
      //     geometry: {
      //       type: "Point",
      //       coordinates: [parseFloat(coordinates.Longitude), parseFloat(coordinates.Latitude)],
      //       location: coordinates.Name,
      //     },
      //   };
      // });

      // Add the current owner to the array
      // formattedStockTransfers.push({
      //   properties: {
      //     Name: currentOwner.Name,
      //     entityId: currentOwner._id,
      //     StockTransferTimestamp: new Date().getTime(), // You can set this to any suitable value for the current owner
      //   },
      //   geometry: {
      //     type: "Point",
      //     coordinates: [parseFloat(currentOwnerLocation.Longitude), parseFloat(currentOwnerLocation.Latitude)],
      //     location: currentOwnerLocation.Name,
      //   },
      // });

      // Sort the array by StockTransferTimestamp
      // formattedStockTransfers.sort((a, b) => b.properties.StockTransferTimestamp - a.properties.StockTransferTimestamp);

      // console.log(formattedStockTransfers);
      // setData(formattedStockTransfers);
    }
  }, [result]);

  return (
    <Card>
      <Box sx={{ textAlign: "center" }} pt={2} bgcolor={"#FAF1E4"}>
        <img src={OrigoLogoBlack} height={"50px"} width="auto" alt="Traceability by Sinisana" />
      </Box>
      <CardMedia
        component="img"
        height="200px"
        src={Pallet}
        alt={"Pallet"}
        sx={{
          objectFit: "contain",
          // border: 1,
          paddingX: 5,
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h4" component="div" color="#362706" fontWeight={500}>
          {result["Product/Name"]}
        </Typography>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="body1" pt={1} color="#362706" fontWeight={500}>
            Load Capacity:
          </Typography>
          <Typography gutterBottom variant="body1" pt={1} color="#0e364a" fontWeight={500}>
            {result["Product/Attributes"] ? JSON.parse(result["Product/Attributes"])["loadCapacity"] : "-"}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="body1" color="#362706" fontWeight={500}>
            Production Date:
          </Typography>
          <Typography gutterBottom variant="body1" color="#0e364a" fontWeight={500}>
            {result["Product/Attributes"] ? JSON.parse(result["Product/Attributes"])["productionDate"] : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="body1" color="#362706" fontWeight={500}>
            Raw Material:
          </Typography>
          <Typography gutterBottom variant="body1" color="#0e364a" fontWeight={500}>
            {result["Product/Attributes"] ? JSON.parse(result["Product/Attributes"])["rawMaterial"] : "-"}
          </Typography>
        </Stack>

        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "relative",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              transition: "right 0.3s",
            }}
          >
            <Box style={{ height: `calc(35vh - 20px)`, width: "100%" }} marginTop={"16px"}>
              {currentLocation.Latitude && currentLocation.Longitude ? (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCKZ7S9XSaIEFCkyn694xKqwoe5Xi_-gU4",
                  }}
                  defaultCenter={{
                    lat: currentLocation.Latitude ? Number(currentLocation.Latitude) : 4.210484,
                    lng: currentLocation.Longitude ? Number(currentLocation.Longitude) : 101.975769,
                  }}
                  defaultZoom={4}
                  options={{
                    fullscreenControl: false,
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                  }}
                >
                  {currentLocation && currentLocation !== "{}" ? (
                    <Marker lat={currentLocation.Latitude} lng={currentLocation.Longitude}>
                      <div className="marker" />
                    </Marker>
                  ) : null}
                  {/* {palletLocation.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { entityId } = cluster.properties;
  
                    return (
                      <Marker key={`cluster-${entityId}`} lat={latitude} lng={longitude}>
                        <div className="marker" />
                      </Marker>
                    );
                  })} */}
                </GoogleMapReact>
              ) : null}
            </Box>
          </div>
        </div>

        <Box mt={8}>
          <Timeline
            animate={true}
            // items={data}
            items={historyResult}
          />
        </Box>

        {/* <Box>
          {data.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { entityId, StockTransferTimestamp, Name } = cluster.properties;

            return (
              <Box border={"1px solid black"} sx={{ borderRadius: 6 }} m={2} p={2}>
                <Stack>
                  <Typography variant={"subtitle2"}>{Name}</Typography>
                  <Typography variant={"overline"}>{cluster.geometry.location}</Typography>
                </Stack>
              </Box>
            );
          })}
        </Box> */}

        <Box pt={1.5}>
          <Link href="https://origopallet.com" underline="hover" color="#224B0C" variant="subtitle2">
            Origo™ Pallet
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductInfo;
