import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import CommunityImage2 from "../../assets/smile.png";

import KidingSocial1 from "../../assets/Kiding/Social1.jpg";
import PalunganSocial1 from "../../assets/PaLungan/Social1.jpg";
import PalunganSocial2 from "../../assets/PaLungan/Social2.jpg";

import { format } from "date-fns";

const KidingSocialImages = [KidingSocial1];
const PalunganSocialImages = [PalunganSocial1, PalunganSocial2];

const SocialImpact = (props) => {
  const { origin, distillationData, payment } = props;

  const [beneficiaryCount, setBeneficiaryCount] = useState(0);
  const [lastDistillation, setLastDistillation] = useState(null);

  const [socialImage, setSocialImage] = useState(CommunityImage2);

  const nutritionData = [
    {
      name: "Total Fat",
      gram: "8g",
      dailyValue: "11.00%",
      sub: [
        { name: "Saturated Fat", gram: "5g", dailyValue: "24.00%" },
        { name: "Trans Fat ", gram: "0g", dailyValue: "0%" },
      ],
    },
    {
      name: "Cholesterol",
      gram: "24mg",
      dailyValue: "8.00%",
    },
    {
      name: "Sodium",
      gram: "129mg",
      dailyValue: "5.00%",
    },
    {
      name: "Total Carbs",
      gram: "12g",
      dailyValue: "5.00%",
      sub: [
        { name: "Dietary Fiber", gram: "0g", dailyValue: "0%" },
        { name: "Total Sugars", gram: "12g", dailyValue: "0%" },
      ],
    },
    {
      name: "Protein",
      gram: "8g",
      dailyValue: "0%",
    },
    {
      name: "Vitamin A",
      gram: "750.5IU",
      dailyValue: "28.00%",
    },
    {
      name: "Vitamin D3",
      gram: "136IU",
      dailyValue: "23.00%",
    },
    {
      name: "Calcium ",
      gram: "250.4mg",
      dailyValue: "25.00%",
    },
  ];

  useEffect(() => {
    if (distillationData && distillationData.length > 0) {
      const beneficiarySet = new Set();
      for (const distillation of distillationData) {
        const distillationInfo = JSON.parse(distillation["PrivateInfo"]);
        beneficiarySet.add(distillationInfo["Distilled By"]);
      }
      setBeneficiaryCount(beneficiarySet.size);

      setLastDistillation({
        date: format(new Date(distillationData[0]["ProcessDate"]), "MMM dd yyyy"),
      });
    }
  }, [distillationData]);

  useEffect(() => {
    if (origin) {
      if (origin.name === "Pa' Lungan, Bario") {
        const random = Math.floor(Math.random() * PalunganSocialImages.length);
        setSocialImage(PalunganSocialImages[random]);
      } else if (origin.name === "Kampung Kiding, Padawan, Kuching") {
        const random = Math.floor(Math.random() * KidingSocialImages.length);
        setSocialImage(KidingSocialImages[random]);
      }
    }
  }, [origin]);

  return (
    <div class="reveal" id="socialImpact">
      <Card style={{ backgroundColor: "#f3fbfe" }}>
        <CardContent>
          <Typography variant="overline" color="#042243" fontSize={20} fontWeight="bold">
            Nutrition Information
          </Typography>

          <div class="reveal">
            <Stack direction={"row"} justifyContent={"space-between"} mx={4} mb={2} border={1} px={2} py={1} borderRadius={1}>
              <Stack direction={"column"} spacing={0.5}>
                <Typography fontSize={12}>Serving Size</Typography>
                <Typography fontSize={12} fontWeight={500}>
                  250 ml
                </Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack direction={"column"} spacing={0.5}>
                <Typography fontSize={12}>Calories</Typography>
                <Typography fontSize={12} fontWeight={500}>
                  152 kcal
                </Typography>
              </Stack>
            </Stack>
          </div>

          <Stack spacing={0.5}>
            {nutritionData.map((item) => {
              return (
                <Stack spacing={0.5}>
                  <div class="reveal">
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Stack direction={"row"} spacing={2}>
                        <Typography fontSize={12} fontWeight={500}>
                          {item.name}
                        </Typography>
                        <Typography fontSize={12} fontWeight={500}>
                          {item.gram}
                        </Typography>
                      </Stack>
                      <Typography fontSize={12} fontWeight={500}>
                        {item.dailyValue}
                      </Typography>
                    </Stack>
                  </div>

                  {item.sub &&
                    item.sub.length > 0 &&
                    item.sub.map((subItem) => {
                      return (
                        <div class="reveal">
                          <Stack direction={"row"} justifyContent={"space-between"} ml={2}>
                            <Stack direction={"row"} spacing={2}>
                              <Typography fontSize={12}>{subItem.name}</Typography>
                              <Typography fontSize={12}>{subItem.gram}</Typography>
                            </Stack>
                            <Typography fontSize={12}>{subItem.dailyValue}</Typography>
                          </Stack>
                        </div>
                      );
                    })}
                </Stack>
              );
            })}
          </Stack>

          <div class="reveal">
            <Typography gutterBottom color="#042243" fontSize={10} pt={1.5}>
              The % Daily Value (DV) tells you how much nutrient in a serving of food contributes to a daily diet. 2000 Calories a day is used for
              general nutrition advice.
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SocialImpact;
