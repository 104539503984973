import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";

import Dialog from "@mui/material/Dialog";
import SinisanaBlockchain from "../SinisanaBlockchain";

const BurgerView = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    //document.body.style.height = "800px";
    document.body.style.backgroundImage = "linear-gradient(to bottom right, #edf6f9, #83c5be)";
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", height: "100vh" }}>
      <Container maxWidth="xxl" style={{ backgroundColor: "#FB8500", height: "10px" }}></Container>
      <Container maxWidth="xxl" style={{ backgroundColor: "#FFB703", height: "10px", marginBottom: 20 }}></Container>
      <Container maxWidth="sm" style={{ paddingBottom: "30px" }}>
        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
          <img src="/images/DinoBurger.png" height="auto" width="50%" alt="Halal Traceability by Sinisana" />
        </Box>
        <Box sx={{ textAlign: "center", margin: "30px 0px 5px", color: "black", fontSize: "1.5rem", fontWeight: 600 }}>
          DISCOVER MORE ABOUT YOUR PACK OF DINO BURGER
        </Box>

        <Box sx={{ textAlign: "center", margin: "30px 0px 5px", color: "black", fontSize: "1rem", fontWeight: 600 }}>
          ENTER YOUR UNIQUE CODE
        </Box>

        <Card sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
          <Box
            sx={{
              width: "70%",
              margin: "20px auto",
              padding: "0.2rem 1.2rem",
              fontSize: "1.2rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            <TextField variant="outlined" />
          </Box>

          <Box
            sx={{
              bgcolor: "#FB8500",
              width: "50%",
              margin: "20px auto",
              padding: "1.2rem",
              fontSize: "1.2rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            <a href="/halal/burger" style={{ textDecoration: "none" }}>
              FIND YOUR PACK
            </a>
          </Box>
          <Box
            sx={{
              width: "100%",
              margin: "20px auto",
              padding: "0.2rem 1.2rem",
              fontSize: "0.8rem",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setOpen(true)}
          >
            <InfoIcon />
            You can find your unique code on the product packaging.
          </Box>
        </Card>
      </Container>
      <CodeDialog handleClose={() => setOpen(false)} open={open} />
      <SinisanaBlockchain />
    </Box>
  );
};
const CodeDialog = ({ open, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <img src="/images/code.png" height={"auto"} width="100%" />
    </Dialog>
  );
};
export default BurgerView;
