import React, { useState, useEffect } from "react";
import logo from "./sinisana.png";
import Crabs from "./Crabs.png";
import neocrab from "./neocrab.png";
import sscDish from "./Neocrab/sscDish.jpg";
import softShellCrabs from "./Neocrab/soft-shell-crabs.jpg";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import NcTimeline from "./NCTimeline/NcTimeline";

import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";

const DesktopView = () => {
  // const serverUrl = "https://ncstaging.sinisana.net/api/web-1228";
  // const serverUrl = "http://localhost:4000/web-1228";
  const serverUrl = "https://neocrab.sinisana.net/api/web-1228";

  const navigate = useNavigate();

  const [searching, setSearching] = useState(true);
  const [data, setData] = useState();
  const [dataPostgres, setDataPostgres] = useState();
  const [dataRecipes, setDataRecipes] = useState();
  const [attributes, setAttributes] = useState();
  const [batchInfo, setBatchInfo] = useState();

  useEffect(() => {
    document.body.style.backgroundColor = "#EDF2F6";
    const searchParam = window.location.href;
    search(searchParam);
  }, []);

  const search = async (searchParam) => {
    setSearching(true);
    setData();
    
    await fetch(serverUrl + "/trace/v2", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchText: searchParam,
        // searchText: "https://trace.sinisana.io/aqua?a=EHJbn8A0av5Je_5cCWslT",
        // searchText: "https://localhost:3000/aqua?a=EHJbn8A0av5Je_5cCWslT",
        // searchText: "https://trace.sinisana.io/aqua?a=fwymUeHKCZCy3Mr9H4j-g",
        // searchText: "https://trace.sinisana.io/aqua?a=jjEV4GAFtvQbju6jw_ioP",
        // searchText: "https://trace.sinisana.io/aqua?a=WTXoDjzBG83Kb24vfigvG",
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error("Record Not Found");
        } else {
          throw new Error("Connection Error");
        }
      })
      .then(async (result) => {
        if (result) {
          if (result.data) {
            await fetch(serverUrl + "/tracePack", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                batchId: JSON.parse(result.data[0]["Attributes"])["Pack ID"],
              }),
            })
              .then((response) => response.json())
              .then(async (resultPostgres) => {
                setDataPostgres(resultPostgres.data);
              });

            await fetch(serverUrl + "/recipes", {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((response) => response.json())
              .then(async (resultRecipes) => {
                setDataRecipes(resultRecipes.data);
              });
              
            setData(result.data);
            setAttributes(JSON.parse(result.data[0]["Attributes"]));
            setBatchInfo(result.data[0]["TraceableBOM"]);
          } else setData(null);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        marginBottom: 25,
      }}
    >
      <div style={{ flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={neocrab}
            height="auto"
            alt="Seafood Traceability by Sinisana"
            style={{ width: "15%", minWidth: 125 }}
          />
        </Box>

        {searching ? (
          <Box
            sx={{
              display: "flex",
              marginTop: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}

        {!searching && !data ? (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  margin: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card
                  style={{
                    padding: 10,
                    width: "100%",
                    borderRadius: 8,
                    boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                    overflow: "hidden",
                    backgroundColor: "#9D1B2B",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <CardContent>
                    <p>
                      The traceability code could not be found. Please scan
                      again.
                    </p>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        {data && attributes && batchInfo ? (
          <>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Crabs}
                    height="auto"
                    width="80%"
                    alt="Product"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                <Stack
                  sx={{
                    display: "flex",
                    padding: 3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  direction="column"
                >
                  <Typography gutterBottom variant="h4" component="div">
                    PREMIUM QUALITY
                    <br />
                    SOFT SHELL CRABS
                  </Typography>

                  <Card
                    style={{
                      padding: 10,
                      borderRadius: 8,
                      overflow: "hidden",
                      backgroundColor: "#3B71B4",
                      color: "white",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={"auto"}>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                          >
                            {" "}
                            Min. Weight:{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                          {attributes
                            ? attributes["Min Net Weight"]
                            : "" + " " + attributes
                            ? attributes["Min Net Weight Unit"]
                            : ""}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={"auto"}>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                          >
                            {" "}
                            Processing Date:{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                          {attributes["Production Date"]
                            ? format(new Date(attributes["Production Date"]), "PP")
                            : null}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={"auto"}>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                          >
                            Expiry Date:{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                          {attributes["Expiry Date"]
                            ? format(new Date(attributes["Expiry Date"]), "PP")
                            : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: "#DDDDDD",
                paddingTop: "20px",
                marginTop: "20px",
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" component="div" textAlign={"center"}>
                  Trace My Journey
                </Typography>
              </Grid>
              <Box padding={"16px"}>
                <NcTimeline
                  data={data}
                  dataPostgres={dataPostgres}
                  handleClick={handleClick}
                />
              </Box>
            </Grid>

            <Container justifyContent="center" alignItems="center">
              <Typography
                variant="h4"
                component="div"
                textAlign={"center"}
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                Recipes
              </Typography>

              <Grid
                flexDirection={"row"}
                container
                justifyContent="center"
                spacing={2}
              >
                {/* Loop through dataRecipe to get recipes */}
                {dataRecipes.map((recipe, index) => (
                  <Grid item xs={12} md={4}>
                    <Card
                      sx={{
                        width: "100%",
                        height: "100%"
                      }}
                    >
                      <CardHeader title={recipe["title"]} />
                      <CardMedia
                        component="img"
                        height="194"
                        image={index == "0" ? sscDish : softShellCrabs}
                        alt="Paella dish"
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {recipe["shortDescription"]
                            ? recipe["shortDescription"]
                            : ""}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "20px",
                          }}
                        >
                          <a
                            onClick={() => {
                              // pass postgres data to processing page
                              navigate(`/aqua/recipes/${recipe["id"]}`, {
                                state: {
                                  dataRecipes: recipe["id"],
                                },
                              });
                            }}
                            style={{
                              textDecoration: "none",
                              color: "black",
                              cursor: "pointer",
                            }}
                          >
                            <Chip
                              label="View Recipe"
                              variant="outlined"
                              size="medium"
                              style={{
                                border: "1px solid #000",
                                minWidth: "150px",
                              }}
                            />
                          </a>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </>
        ) : null}
      </div>
      <Grid container>
        <Grid item xs={12} textAlign="center" style={{ padding: "20px" }}>
          <a
            href="https://www.sinisana.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              height="auto"
              style={{ width: "15%", minWidth: 125 }}
              alt="Seafood Traceability by Sinisana"
            />
          </a>
        </Grid>
      </Grid>

      <style jsx>{`
        @media only screen and (max-width: 600px) {
          img {
            width: 75%;
          }
        }
      `}</style>
    </Box>
  );
};

export default DesktopView;
