import React, { useEffect } from "react";
import "./TimelineOrigo.css";
import { format } from "date-fns";
import { gsap } from "gsap";

const Timeline = (props) => {
  const { items, handleClick, animate } = props;

  const animateFromTo = (elem, direction) => {
    const offset = 1000;
    let x = direction * offset;
    let y = 0;

    direction = direction | 1;

    for (let i = 0; i < items.length; i++) {
      if (elem.classList.contains("slide_from_bottom_" + i)) {
        x = 0;
        y = offset;
        var delay = i;
      }
    }

    gsap.fromTo(
      elem,
      { y: y, x: x, autoAlpha: 0 },
      {
        duration: 1,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expoScale(1, 2)",
        overwrite: "auto",
        delay: delay,
      }
    );
  };

  const hide = (elem) => {
    gsap.set(elem, { autoAlpha: 0 });
  };
  /*
  useEffect(() => {
    gsap.utils.toArray(".animate").forEach(function (elem) {
      hide(elem);
      animateFromTo(elem);
    });
  }, []);*/
  useEffect(() => {
    if (animate)
      gsap.utils.toArray(".animate").forEach(function (elem) {
        hide(elem);
        animateFromTo(elem);
      });
  }, [animate]);

  return (
    <div>
      <div className="timelineOrigo">
        <ul>
          {items.map((te, idx) => {
            const { Block, BlockHash, CurrentOwner, Instant } = te;

            const name = CurrentOwner.Name;
            const location = JSON.parse(CurrentOwner.Location)["Name"];
            // const { entityId, StockTransferTimestamp, Name } = te.properties;
            // const location = te.geometry.location;
            return (
              <li key={`${name}_${Instant}`} className={`animate ${"slide_from_bottom_" + idx}`}>
                <div className="contentOrigo">
                  <h3>{name}</h3>
                  <p>{location}</p>
                </div>
                <div className={"timeOrigo"}>
                  <h4>{format(new Date(Instant), "MMM dd yyyy")}</h4>
                </div>
              </li>
            );
          })}
          <div style={{ clear: "both" }}></div>
        </ul>
      </div>
    </div>
  );
};

export default Timeline;
