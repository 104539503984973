import React from "react";
import Typography from "@mui/material/Typography";

const ProductDescriptionPanel = (props) => {
  const { fontSize, fontWeight, color, marginTop, marginBottom } = props;

  return (
    <Typography
      fontSize={fontSize + "px"}
      fontWeight={fontWeight}
      color={color}
      marginTop={marginTop + "px"}
      marginBottom={marginBottom + "px"}
    >
      {props.children && props.children}
    </Typography>
  );
};

export default ProductDescriptionPanel;
