import React from "react";
import { Container, Box, Card, CardContent } from "@mui/material";

const SinisanaBlockchain = (props) => {
  const { data } = props;

  return (
    <Container maxWidth="xxl" style={{ marginTop: "auto", padding: 0, backgroundColor: "#e0e0e0" }}>
      <Card
        style={{
          width: "100%",
          border: "none",
          overflow: "hidden",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          backgroundColor: "#e0e0e0",
        }}
      >
        <CardContent>
          Halal Traceability provided by{" "}
          <a href="https://www.sinisana.net" target="_blank" rel="noopener noreferrer">
            Sinisana Blockchain
            <br />
          </a>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SinisanaBlockchain;
