import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

const InfoDialog = (props) => {
  const { visible, onClose, item } = props;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState([]);
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (visible) {
      if (item) {
        setTitle(item.title);
        setDescription(item.description);
      }
    }
  }, [visible]);

  return (
    <Dialog onClose={onClose} open={visible} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
      <DialogTitle sx={{ typography: "subtitle2", color: "#064420", fontSize: 20 }}>{title}</DialogTitle>
      <DialogContent>
        {description &&
          description.blocks &&
          description.blocks?.map((block, blockIndex) => (
            <Typography key={blockIndex} color="#064420" variant="body2" style={{ textAlign: block.tunes.anyTuneName.alignment }} gutterBottom>
              {block.data.text}
            </Typography>
          ))}
      </DialogContent>
    </Dialog>
  );
};
export default InfoDialog;
