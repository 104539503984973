import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  useTheme,
  useMediaQuery,
  Alert,
  AlertTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WaterIcon from "@mui/icons-material/Water";

import { useLocation } from "react-router-dom";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useNavigate } from "react-router-dom";

import process04 from "../process04.jpg";
import crabOrders from "../crabOrders.jpg";
import logo from "../../sinisana.png";

import "../Neocrab.css";

const Processing = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const location = useLocation();
  const processingData = location.state?.dataPostgres;

  useEffect(() => {
    document.body.style.backgroundColor = "#EDF2F6";
  }, []);

  return (
    <>
      <Grid container style={{ backgroundColor: "#ffffff", padding: "15px" }}>
        <Grid item>
          <a onClick={() => navigate(-1)}>
            <IconButton>
              <ArrowBackIosNewIcon />
            </IconButton>
          </a>
        </Grid>
        <Grid item flex={1}>
          <Typography variant="h4">Processing</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"} spacing={2} padding={2}>
        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    ...(mdUp && {
                      backgroundImage: `url(${process04})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={process04}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Freshly caught crabs from the farm are given the royal
                      treatment upon arrival. They are carefully graded and
                      assigned a batch number before being packaged. Each
                      plastic package bears the proud stamp of their batch
                      number, which serves as their unique identifier.
                    </Typography>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      The crabs are then blast frozen at incredibly low
                      temperature of{" "}
                      <b>
                        {processingData
                          ? processingData["FactoryFreezingRecord"][0][
                              "freezerTemperature"
                            ] + " °C"
                          : "-19°C"}
                      </b>{" "}
                      , ensuring their freshness is locked in. Stored in a
                      freezer and cold room, these crabs are kept in optimal
                      conditions until a new order comes in. Then, they are
                      packed with care into a box with their production batch
                      number, ready for their next adventure.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    ...(mdUp && {
                      backgroundImage: `url(${crabOrders})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={crabOrders}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item flex={1}>
                    <Typography>
                      So if you're wondering where your delicious crabs came
                      from, simply scan the QR code on your packaging to find
                      out!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Alert
            icon={<TipsAndUpdatesIcon fontSize="large" />}
            variant="filled"
            severity="info"
          >
            <AlertTitle>Here's another fun fact for you -</AlertTitle>
            <Typography>
              Did you know that crabs can be killed in a humane and tasty way?
              At our facility, we prioritize the well-being of our crustacean
              friends by using temperatures between -18°C to -20°C degrees to
              put them at ease before they pass on. Not only does this process
              prevent unnecessary suffering, but it also makes for a more
              delectable dish. By avoiding other methods that cause stress and
              adrenaline production, we can ensure that our crabs have a pure
              and delicious taste. So, the next time you order crab, know that
              it was harvested with care and respect for the animal.
            </Typography>
          </Alert>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} textAlign="center" style={{ padding: "20px" }}>
          <a
            href="https://www.sinisana.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              height="auto"
              style={{ width: "15%", minWidth: 125 }}
              alt="Seafood Traceability by Sinisana"
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default Processing;
