import React, { useEffect } from "react";
import "./Timeline.css";

import { gsap } from "gsap";

const Timeline = (props) => {
  const { items, handleClick, animate } = props;

  const animateFromTo = (elem, direction) => {
    const offset = 1000;
    let x = direction * offset;
    let y = 0;

    direction = direction | 1;

    for (let i = 0; i < items.length; i++) {
      if (elem.classList.contains("slide_from_bottom_" + i)) {
        x = 0;
        y = offset;
        var delay = i;
      }
    }

    gsap.fromTo(
      elem,
      { y: y, x: x, autoAlpha: 0 },
      {
        duration: 1,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expoScale(1, 2)",
        overwrite: "auto",
        delay: delay,
      }
    );
  };

  const hide = (elem) => {
    gsap.set(elem, { autoAlpha: 0 });
  };
  /*
  useEffect(() => {
    gsap.utils.toArray(".animate").forEach(function (elem) {
      hide(elem);
      animateFromTo(elem);
    });
  }, []);*/
  useEffect(() => {
    if (animate)
      gsap.utils.toArray(".animate").forEach(function (elem) {
        hide(elem);
        animateFromTo(elem);
      });
  }, [animate]);

  return (
    <div>
      <div className="timeline">
        <ul>
          {items.map((te, idx) => {
            return (
              <li
                key={`${te.title}_${te.date}`}
                className={`animate ${"slide_from_bottom_" + idx}`}
                onClick={() => handleClick(te)}
              >
                <div className="content">
                  <h3>{te.title}</h3>
                  <p>{te.description}</p>
                </div>
                <div className={"time"}>
                  <h4>{te.date}</h4>
                </div>
              </li>
            );
          })}
          <div style={{ clear: "both" }}></div>
        </ul>
      </div>
    </div>
  );
};

export default Timeline;
