import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  useTheme,
  useMediaQuery,
  Alert,
  AlertTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import WaterIcon from "@mui/icons-material/Water";
import { useNavigate } from "react-router-dom";

import hatchery01 from "../hatchery01.jpg";
import hatchery02 from "../hatchery02.jpg";
import broodstock from "../broodstock.jpg";
import logo from "../../sinisana.png";

import "../Neocrab.css";

const Hatchery = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = "#EDF2F6";
  }, []);

  return (
    <>
      <Grid container style={{ backgroundColor: "#ffffff", padding: "15px" }}>
        <Grid item>
        <a onClick={() => navigate(-1)}>
            <IconButton>
              <ArrowBackIosNewIcon />
            </IconButton>
          </a>
        </Grid>

        <Grid item flex={1}>
          <Typography variant="h4">Sematan Hatchery</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"} spacing={2} padding={2}>
        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",

                    ...(mdUp && {
                      backgroundImage: `url(${hatchery01})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={hatchery01}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Our hatchery is located in Sematan, a beautiful coastal
                      town in Sarawak where the abundance of marine life and
                      natural resources provide the perfect environment for our
                      aquaculture operations.
                    </Typography>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Our crabs start their journey with the arrival of
                      carefully selected broodstock, which are immediately
                      quarantined for a day to ensure their health and wellbeing
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <br />
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    ...(mdUp && {
                      backgroundImage: `url(${broodstock})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mdUp && (
                    <img
                      src={broodstock}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Once the broodstock are deemed healthy, they are given a
                      tag and placed in a tank, where they stay for many days
                      until they spawn eggs. The eggs are then transferred to an
                      incubation tank for monitoring until hatching, at which
                      point the tiny zoea are carefully stocked into larval
                      tanks.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <br />
        <Grid item xs={12} md={8}>
          <Card className="descriptionCard">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...(mdUp && {
                      backgroundImage: `url(${hatchery02})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 400,
                    }),
                  }}
                >
                  {!mdUp && (
                    <img
                      src={hatchery02}
                      height="auto"
                      alt="Product"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <WaterIcon style={{ color: "#62CDFF" }} />
                  </Grid>
                  <Grid item flex={1}>
                    <Typography>
                      Our team diligently monitors the growth of the zoea until
                      they reach approximately day 10, when they have developed
                      into the megalopa stage. At this point, we do a tank
                      transfer before harvesting the crabs according to tank and
                      transferring them to our farm.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Alert
            icon={<TipsAndUpdatesIcon fontSize="large" />}
            variant="filled"
            severity="info"
          >
            <AlertTitle>Here's a fun fact for you -</AlertTitle>
            <Typography>
              After the broodstock crabs have completed their important role in
              the egg production process, they are released back into the wild,
              allowing them to continue contributing to the health of our
              oceans.
            </Typography>
          </Alert>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} textAlign="center" style={{ padding: "20px" }}>
          <a
            href="https://www.sinisana.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              height="auto"
              style={{ width: "15%", minWidth: 125 }}
              alt="Seafood Traceability by Sinisana"
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default Hatchery;
