import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import ProductInfo from "./ScanResultComponents/ProductInfo";
import SocialImpact from "./ScanResultComponents/SocialImpact";
import ProductJourney from "./ScanResultComponents/ProductJourney";
import { format } from "date-fns";

const ScanResult = (props) => {
  const { result, handleScrollClickSocialImpact, handleScrollClickProductJourney } = props;

  const [litSaraProduct, setLitSaraProduct] = useState({});
  const [essentialOil, setEssentialOil] = useState({});
  const [origin, setOrigin] = useState({});
  const [payment, setPayment] = useState({});
  const [distillationData, setDistillationData] = useState([]);
  const [qcDocument, setQCDocument] = useState([]);

  useEffect(() => {
    if (result) {
      setLitSaraProduct({
        name: result["Product/Name"],
        location: "Location",
        productionDate: result["Product/ProductionDate"],
        expiryDate: result["Product/ExpiryDate"],
        batchNo: result["Product/TraceCodes"] ? result["Product/TraceCodes"][0].split("_")[1] : "",
      });

      // if (result["TraceableBOM"] && result["TraceableBOM"].length) {
      //   setEssentialOil({
      //     name: result["TraceableBOM"][0]["Name"],
      //   });

      //   const traceableBOM = result["TraceableBOM"][0];

      //   if (traceableBOM["StockTransfer"] && traceableBOM["StockTransfer"].length) {
      //     const { Payment, TraceableOrigin } = traceableBOM["StockTransfer"][0];
      //     setOrigin({
      //       _id: TraceableOrigin["_id"],
      //       name: TraceableOrigin["Name"],
      //     });

      //     if (Payment && Payment.length) {
      //       setPayment({
      //         _id: Payment[0]["_id"],
      //         date: Payment[0]["PaymentDate"],
      //         payer: Payment[0]["Payer"]["Name"],
      //         recipient: Payment[0]["Recipient"]["Name"],
      //       });
      //     }
      //   }

      //   if (traceableBOM["Processes"] && traceableBOM["Processes"].length) {
      //     setDistillationData(traceableBOM["Processes"].filter((process) => process["Name"] === "Distillation"));
      //   }

      //   if (traceableBOM["Documents"] && traceableBOM["Documents"].length) {
      //     let documents = [];
      //     for (const document of result["TraceableBOM"][0]["Documents"]) {
      //       let documentTypes = document.Tags.includes("Quality Control");
      //       if (documentTypes) {
      //         let item = {
      //           name: document["Creator"]["Name"],
      //           date: format(new Date(document["UploadTimestamp"]), "MMM dd yyyy"),
      //         };
      //         documents.push(item);
      //       }
      //     }
      //     setQCDocument(documents);
      //   }
      // }
    }
  }, [result]);

  return (
    <Stack spacing={2} paddingTop={4} paddingBottom={4}>
      <ProductInfo
        litSaraProduct={litSaraProduct}
        essentialOil={essentialOil}
        qcDocument={qcDocument}
        origin={origin}
        handleScrollClickProductJourney={handleScrollClickProductJourney}
        handleScrollClickSocialImpact={handleScrollClickSocialImpact}
      />

      <SocialImpact origin={origin} distillationData={distillationData} payment={payment} />

      <ProductJourney origin={origin} litSaraProduct={litSaraProduct} distillationData={distillationData} />
    </Stack>
  );
};

export default ScanResult;
