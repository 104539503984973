import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { Grid } from "@mui/material";

const ProductAttributePanel = (props) => {
  const { fontSize, fontWeight, color, label, value } = props;

  const [itemValue, setItemValue] = useState("");

  useEffect(() => {
    setItemValue(checking(value));
  }, [value]);

  const checking = (item) => {
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (iso8601Pattern.test(item)) {
      return format(new Date(item), "yyyy-MM-dd");
    } else {
      return item;
    }
  };

  return (
    <Grid container>
      <Grid item xs={3.5}>
        <Typography fontSize={fontSize + "px"} fontWeight={fontWeight} color={color}>
          {label || "Attribute"}
        </Typography>
      </Grid>
      <Grid item xs={0.5}>
        <Typography fontSize={fontSize + "px"} fontWeight={fontWeight} color={color}>
          :
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography fontSize={fontSize + "px"} fontWeight={fontWeight} color={color} align="justify">
          {!value ? "Value" : itemValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductAttributePanel;
