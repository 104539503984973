import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import AllLitsaraProductImage from "../assets/Product.jpg";

const ProductionDialog = (props) => {
  const { visible, onClose } = props;

  return (
    <Dialog onClose={onClose} open={visible} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
      <DialogTitle>Production</DialogTitle>
      <DialogContent>
        <Typography gutterBottom color="#064420" variant="body2">
          The <i>Litsea cubeba</i> essential oil is a key ingredient in a variety of products that are revered for their
          natural and therapeutic properties. To create the final product, the essential oil is blended with carefully
          chosen ingredients that complement its unique aroma and benefits. It's a process that requires finesse and a
          keen eye for detail, ensuring that the end result is nothing short of extraordinary.
        </Typography>
        <Box py={1.5} px={0.5}>
          <img
            src={AllLitsaraProductImage}
            alt="AllLitsaraProductImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>

        <Typography gutterBottom color="#064420" variant="body2">
          Once the ingredients are blended to perfection, the product is packaged and labeled, ready to be sold in the
          market. The packaging is just as thoughtful and deliberate as the blending process, with attention paid to
          every detail, from the label to the container. It's a testament to the care and passion that goes into every
          step of the process, leaving you with a product that's as visually stunning as it is effective. So, whether
          you're looking to pamper yourself or seeking a natural remedy, we hope it leaves you with a smile on your
          face.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
export default ProductionDialog;
