import Typography from "@mui/material/Typography";

const ProductNamePanel = (props) => {
  const { fontSize, fontWeight, color, marginTop, marginBottom, textAlign } = props;

  return (
    <Typography
      gutterBottom
      component="div"
      color={color}
      fontSize={fontSize + "px"}
      fontWeight={fontWeight}
      marginTop={marginTop + "px"}
      marginBottom={marginBottom + "px"}
      textAlign={textAlign}
    >
      {props.children ? props.children : "Product Name"}
    </Typography>
  );
};

export default ProductNamePanel;
