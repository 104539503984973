import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

import LogoImage from "../../assets/Logo/almaraiLogo.svg";
import ProductImage from "../../assets/ProductImage/milk250ml.png";

const ProductInfo = (props) => {
  const {
    litSaraProduct,
    essentialOil,
    qcDocument,
    origin,
    handleScrollClickProductJourney,
    handleScrollClickSocialImpact,
  } = props;

  return (
    <Card>
      <Box sx={{ textAlign: "center" }} pt={2}>
        <img src={LogoImage} height={"50px"} width="auto" alt="Logo Image" />
      </Box>
      <CardMedia
        component="img"
        height="220px"
        src={ProductImage}
        alt={"Product Image"}
        sx={{
          objectFit: "contain",
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="#042243" fontWeight={500}>
          {/* Fresh Milk Full Fat - 250ml */}
          {litSaraProduct.name}
        </Typography>

        <Typography gutterBottom fontSize={13} color="#042243" fontWeight={400}>
          {/* Almarai Full Fat 100% Fresh Cow's Milk is a rich source of protein and calcium. Prepared without preservatives and without milk powder. */}
          Almarai 100% Fresh Cow's Milk is a rich source of protein and calcium. Prepared without preservatives and
          without milk powder.
        </Typography>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2" pt={1.5}>
            Brand:
          </Typography>
          <Typography gutterBottom variant="subtitle2" pt={1.5}>
            Almarai
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Batch No:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {litSaraProduct.batchNo}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Production Date:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {litSaraProduct.productionDate}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Expiry Date:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {litSaraProduct.expiryDate}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Manufacture:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            Fresh Dairy Farm
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Halal Certified:
          </Typography>
          <Link
            href="https://www.almarai.com/en/corporate/about-almarai/quality-food-safety"
            underline="hover"
            color="#01709e"
            variant="subtitle2"
          >
            <Typography gutterBottom variant="subtitle2">
              View Certificate
            </Typography>
          </Link>
        </Stack>

        {/* <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Raw Material:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            <i>Litsea cubeba</i>{" "}
            {essentialOil && essentialOil.name ? (essentialOil?.name.split("-")[0].slice(-1) === "L" ? "Leaf Oil" : "Fruit Oil") : null}
          </Typography>
        </Stack> */}

        {/* <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Harvest Location:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {origin?.name}
          </Typography>
        </Stack> */}

        {/* {qcDocument && qcDocument.length > 0 && (
          <Stack direction="row" justifyContent="space-between">
            <Typography gutterBottom variant="subtitle2">
              Quality Check:
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {qcDocument[0].date}
            </Typography>
          </Stack>
        )} */}

        {/* {communityImage && (
          <Box my={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
            <CardMedia
              component="img"
              src={communityImage}
              alt="Image description"
              sx={{ height: 200, objectFit: "cover" }}
            />
          </Box>
        )} */}

        {/* <Typography gutterBottom color="#483434" variant="subtitle2">
          With each purchase of our products, you're helping to support the livelihood and well-being of our community, while also making a{" "}
          <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleScrollClickSocialImpact}>
            positive impact
          </span>{" "}
          on society and supporting its growth.
        </Typography> */}

        <Stack spacing={2} direction={"row"} pt={1.5}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleScrollClickSocialImpact}
            sx={{
              color: "#16236a",
              borderColor: "#16236a",
              width: "50%",
              fontSize: 12,
            }}
          >
            Nutrition Information
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleScrollClickProductJourney}
            sx={{
              color: "#16236a",
              borderColor: "#16236a",
              width: "50%",
              fontSize: 12,
            }}
          >
            Product Journey
          </Button>
        </Stack>
        <Box pt={1}>
          <Link href="https://www.almarai.com/en" underline="hover" color="#01709e" variant="subtitle2">
            Fresh Dairy, Bakery &amp; Poultry Products | Almarai
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductInfo;
