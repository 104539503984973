import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DistillationImage from "../assets/Distill.jpg";
import DistillationImage2 from "../assets/Distillation.jpg";
import KidingDistillation1 from "../assets/Kiding/Distillation1.jpg";
import KidingDistillation2 from "../assets/Kiding/Distillation2.jpg";
import PalunganDistillation1 from "../assets/PaLungan/Distillation1.jpg";
import PalunganDistillation2 from "../assets/PaLungan/Distillation2.jpg";
import PalunganDistillation3 from "../assets/PaLungan/Distillation3.jpg";

import KerebanganDistillation1 from "../assets/Kerebangan/Distillation1.jpg";

import RusuDistillation1 from "../assets/Rusu/Distillation1.jpg";

import TelinganDistillation1 from "../assets/Telingan/Distillation1.jpg";
import TelinganDistillation2 from "../assets/Telingan/Distillation2.jpg";

const KidingDistillationImages1 = [KidingDistillation1];
const KidingDistillationImages2 = [KidingDistillation2];
const PalunganDistillationImages1 = [PalunganDistillation1];
const PalunganDistillationImages2 = [PalunganDistillation2, PalunganDistillation3];

const KerebanganDistillationImages1 = [KerebanganDistillation1];
const RusuDistillationImages1 = [RusuDistillation1];
const TelinganDistillationImages1 = [TelinganDistillation1];
const TelinganDistillationImages2 = [TelinganDistillation2];

const DistillationDialog = (props) => {
  const { origin, visible, onClose } = props;

  const [distillationImage, setDistillationImage] = useState(DistillationImage);
  const [distillationImage2, setDistillationImage2] = useState(DistillationImage2);

  useEffect(() => {
    if (origin && origin.name) {
      if (origin.name.toLowerCase().includes("lungan")) {
        const random = Math.floor(Math.random() * PalunganDistillationImages1.length);
        setDistillationImage(PalunganDistillationImages1[random]);
        const random2 = Math.floor(Math.random() * PalunganDistillationImages2.length);
        setDistillationImage2(PalunganDistillationImages2[random2]);
      } else if (origin.name.toLowerCase().includes("kiding")) {
        const random = Math.floor(Math.random() * KidingDistillationImages1.length);
        setDistillationImage(KidingDistillationImages1[random]);
        const random2 = Math.floor(Math.random() * KidingDistillationImages2.length);
        setDistillationImage2(KidingDistillationImages2[random2]);
      } else if (origin.name.toLowerCase().includes("telingan")) {
        const random = Math.floor(Math.random() * TelinganDistillationImages1.length);
        setDistillationImage(TelinganDistillationImages1[random]);
        const random2 = Math.floor(Math.random() * TelinganDistillationImages2.length);
        setDistillationImage2(TelinganDistillationImages2[random2]);
      } else if (origin.name.toLowerCase().includes("kerebangan")) {
        const random = Math.floor(Math.random() * KerebanganDistillationImages1.length);
        setDistillationImage(KerebanganDistillationImages1[random]);
      } else if (origin.name.toLowerCase().includes("rusu")) {
        const random = Math.floor(Math.random() * RusuDistillationImages1.length);
        setDistillationImage(RusuDistillationImages1[random]);
      }
    }
  }, [origin]);

  return (
    <Dialog onClose={onClose} open={visible} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
      <DialogTitle>Distillation</DialogTitle>
      <DialogContent>
        <Box pb={1.5} px={0.5}>
          <img
            src={distillationImage}
            alt="DistillationImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>

        <Typography gutterBottom color="#064420" variant="body2">
          The essential oil extracted from the <i>Litsea cubeba</i> fruits and leaves is a true labor of love that involves a meticulous 3-hour
          distillation process. The process takes place in a small community distillery that's powered by the raw passion of the local artisans. It's
          a sight to behold, as the expert craftsmen use their time-honored techniques to extract the purest essence from the bountiful harvest.
        </Typography>
        <Box py={1.5} px={0.5}>
          <img
            src={distillationImage2}
            alt="DistillationImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>
        <Typography gutterBottom color="#064420" variant="body2">
          Once the distillation process is complete, the precious essential oil is stored in a designated bottle, carefully labeled and made available
          for transfer to the Sarawak Biodiversity Center. It's a moment of immense pride for the local community, as they witness their hard work and
          dedication transform into a tangible product that's valued by people around the world. So, whether you're looking for a unique and aromatic
          oil for your beauty routine or seeking a natural remedy for your ailments, this essential oil is sure to captivate your senses and leave you
          with a lasting impression.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
export default DistillationDialog;
