import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import halal from "./halalcert.pdf";
const HalalCertViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  useEffect(() => {
    setNumPages(numPages);
  }, []);

  return (
    <div>
      <Document
        onLoadError={(error) => console.log(error)}
        file={{ url: "https://intersect-marketing.s3.us-west-2.amazonaws.com/halalcert.pdf" }}
        loading={"Loading..."}
      >
        <Page pageNumber={pageNumber} scale={0.75} />
      </Document>
    </div>
  );
};

export default HalalCertViewer;
