import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LitSaraLogo from "../LitSaraLogo.png";

const Search = (props) => {
  const { data, selectedProduct, selectedPackaging, selectedBatchNo, handleChangeProduct, handleChangePackaging, handleChangeBatchNo, handleSubmit } =
    props;
  return (
    <Stack
      padding={{
        xs: 4,
        md: 8,
      }}
      spacing={4}
    >
      <Box sx={{ backgroundColor: "#93a63e", borderRadius: "10px", textAlign: "center", padding: 2 }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={LitSaraLogo} height={"180px"} width="auto" alt="Traceability by Sinisana" />
        </Box>
        <Typography sx={{ color: "white" }}>
          DISCOVER MORE ABOUT <br />
          LITSARA PRODUCT
        </Typography>
        <Typography variant="caption" sx={{ color: "white", mt: 2 }}>
          Litsara® Traceability
        </Typography>
      </Box>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Product</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedProduct}
          label="Product"
          onChange={handleChangeProduct}
          sx={{
            backgroundColor: "#fff",
          }}
        >
          {Object.keys(data).map((item) => {
            return (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Packaging</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedPackaging}
          label="Packaging"
          onChange={handleChangePackaging}
          sx={{
            backgroundColor: "#fff",
          }}
          disabled={selectedProduct === ""}
        >
          {data[selectedProduct] &&
            Object.keys(data[selectedProduct]).map((item) => {
              return (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <TextField
          placeholder="Enter Code Here"
          variant="outlined"
          value={selectedBatchNo}
          onChange={handleChangeBatchNo}
          sx={{
            backgroundColor: "#fff",
          }}
          disabled={selectedPackaging === "" || selectedProduct === ""}
        />
      </FormControl>

      <Button
        sx={{
          bgcolor: "#1C6758",
          textAlign: "center",
          color: "#fffde7",
          ":hover": {
            bgcolor: "#125447",
          },
        }}
        variant="contained"
        onClick={() => {
          handleSubmit();
        }}
        disabled={selectedProduct === "" || selectedPackaging === "" || selectedBatchNo === ""}
      >
        Trace Me Back
      </Button>
    </Stack>
  );
};

export default Search;
