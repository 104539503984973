import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import BackgroundImage1 from "./assets/Background/bg-1.jpg";
import BackgroundImage2 from "./assets/Background/bg-2.jpg";
import BackgroundImage3 from "./assets/Background/bg-3.jpg";
import BackgroundImage4 from "./assets/Background/bg-4.jpg";
import BackgroundImage5 from "./assets/Background/bg-5.jpg";
import BackgroundImage6 from "./assets/Background/bg-6.jpg";

import "./Litsara.css";

import ScanResult from "./components/ScanResult";
import NotFound from "./components/NotFound";

// const backendUrl = "https://abs.sinisana.net/api/_1125";
const backendUrl = "https://sbc.demos.sinisana.io/api/_1125";
// const backendUrl = "http://localhost:4000/_1125";

const BackgroundArray = [
  BackgroundImage1,
  BackgroundImage2,
  BackgroundImage3,
  BackgroundImage4,
  BackgroundImage5,
  BackgroundImage6,
];

// LitsaraTrace is for traceability using QR code scanning. This should be the only component that is used for QR code scanning. The other components are for searching, which to be discarded.

const AlmaraiResult = () => {
  const traceCode = window.location.href;

  const [searching, setSearching] = useState(true);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const random = Math.floor(Math.random() * 5);
    document.body.style.backgroundImage = `url(${BackgroundArray[random]})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundPosition = "center";
  }, []);

  useEffect(() => {
    fetch(`${backendUrl}/trace/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        traceCode: traceCode,
        // traceCode: "https://trace.sinisana.io/almarai?a=CM1000_20231027A",
        // traceCode: "https://trace.sinisana.io/litsara?a=LIT107-006_NW220340",
        // traceCode: "https://trace.sinisana.io/litsara?a=LIT106-001_21/KL-17",
        // traceCode: "https://trace.sinisana.io/litsara?a=LIT109-004_SH09012023",
        // traceCode: "https://trace.sinisana.io/litsara?a=LIT104-004_CD24032023",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success" && res.data) {
          setResult(res.data);
        } else {
          setResult(null);
        }
      })
      .finally(() => {
        setSearching(false);
      });
  }, [traceCode]);

  useEffect(() => {
    setResult({ data: "Data" });
    setSearching(false);
  }, []);

  const handleScrollClickSocialImpact = () => {
    const titleElement = document.getElementById("socialImpact");
    if (titleElement) {
      window.scrollTo({
        top: titleElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleScrollClickProductJourney = () => {
    const titleElement = document.getElementById("productJourney");
    if (titleElement) {
      window.scrollTo({
        top: titleElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 10;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        {searching ? (
          <Box
            sx={{
              display: "flex",
              marginTop: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : result ? (
          <ScanResult
            result={result}
            handleScrollClickSocialImpact={handleScrollClickSocialImpact}
            handleScrollClickProductJourney={handleScrollClickProductJourney}
          />
        ) : (
          <NotFound />
        )}
      </Container>
    </Box>
  );
};

export default AlmaraiResult;
