import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format, isValid, subDays } from "date-fns";
import HarvestDialog from "../HarvestDialog";
import DistillationDialog from "../DistillationDialog";
import QualityCheckDialog from "../QualityCheckDialog";
import ProductionDialog from "../ProductionDialog";

const ProductJourney = (props) => {
  const { origin, litSaraProduct, distillationData } = props;

  const [harvestDate, setHarvestDate] = useState(null);
  const [lastDistillation, setLastDistillation] = useState(null);

  const [harvestDialog, setHarvestDialog] = useState(false);
  const [distillationDialog, setDistillationDialog] = useState(false);
  const [qualityCheckDialog, setQualityCheckDialog] = useState(false);
  const [productionDialog, setProductionDialog] = useState(false);

  useEffect(() => {
    if (distillationData && distillationData.length > 0) {
      setLastDistillation({
        date: format(new Date(distillationData[0]["ProcessDate"]), "MMM dd yyyy"),
      });
      const lastDistillationDate = new Date(distillationData[0]["ProcessDate"]);
      const harvestDay = subDays(lastDistillationDate, 1);
      setHarvestDate(format(harvestDay, "MMM dd yyyy"));
    }
  }, [distillationData]);

  return (
    <div class="reveal" id="productJourney">
      <Card style={{ backgroundColor: "#042243" }}>
        <CardContent>
          <Typography gutterBottom variant="overline" color="#e6f6fc" fontSize={20} fontWeight="bold" textDecoration="underline">
            Product Journey
          </Typography>
          <Stepper orientation="vertical">
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#f3fbfe", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Milking and Collection
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Typography color="#064420" variant="body2">
                          On <strong>October 10, 2023</strong>, dairy farmers collect fresh milk from the cows through milking machines. The collected
                          milk is immediately stored in tanks on the farm.
                        </Typography>
                        {/* <IconButton
                          onClick={() => {
                            setHarvestDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton> */}
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#f3fbfe", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Processing and Pasteurization
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Typography color="#064420" variant="body2">
                          On the same day, <strong>October 10, 2023</strong>, the collected milk is transported to a processing plant. At the
                          processing plant, the milk is pasteurized to kill harmful bacteria, homogenized to ensure consistency, and packaged in
                          various containers.
                        </Typography>
                        {/* <IconButton
                          onClick={() => {
                            setDistillationDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton> */}
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#f3fbfe", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Standardization and Homogenization
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                        <Typography color="#064420" variant="body2">
                          After pasteurization, the milk may undergo standardization, where fat content is adjusted, and homogenization, which breaks
                          down fat globules to prevent separation.
                        </Typography>
                        {/* <IconButton
                          onClick={() => {
                            setQualityCheckDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton> */}
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#f3fbfe", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Packaging and Distribution
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Typography color="#064420" variant="body2">
                          On <strong>October 11, 2023</strong>, the processed and quality-checked milk is distributed to retail outlets for consumers
                          to purchase and enjoy.
                        </Typography>
                        {/* <IconButton
                          onClick={() => {
                            setProductionDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton> */}
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
          </Stepper>
        </CardContent>
      </Card>

      <HarvestDialog origin={origin} visible={harvestDialog} onClose={() => setHarvestDialog(false)} />

      <DistillationDialog origin={origin} visible={distillationDialog} onClose={() => setDistillationDialog(false)} />

      <QualityCheckDialog origin={origin} visible={qualityCheckDialog} onClose={() => setQualityCheckDialog(false)} />

      <ProductionDialog origin={origin} visible={productionDialog} onClose={() => setProductionDialog(false)} />
    </div>
  );
};

export default ProductJourney;
