import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import LitSaraImage from "../../LitSaraProduct.json";
import LitSaraLogoHorizontal from "../../LitSaraLogoHorizontal.png";
import HairConditioner from "../../assets/HairConditioner.png";
import BodyWash60ml from "../../assets/BodyWash60ml.png";
import BodyOil60ml from "../../assets/BodyOil60ml.jpg";
import NaturalShampoo60ml from "../../assets/NaturalShampoo60ml.png";
import WetWipe10s from "../../assets/WetWipe10s.png";
import BorneoBreezeBodyOil60ml from "../../assets/BorneoBreezeBodyOil60ml.jpg";
import PremiumMassageOil15ml from "../../assets/PremiumMassageOil15ml.jpg";
import BlissMassageOil15ml from "../../assets/BlissMassageOil15ml.png";
import RefreshingMassageOil15ml from "../../assets/RefreshingMassageOil15ml.png";
import SoothingMassageOil15ml from "../../assets/SoothingMassageOil15ml.png";
import RelaxingMassageOil15ml from "../../assets/RelaxingMassageOil15ml.png";

import KidingCommunity1 from "../../assets/Kiding/Community1.jpg";

import PalunganCommunity1 from "../../assets/PaLungan/Community1.jpg";
import PalunganCommunity2 from "../../assets/PaLungan/Community2.jpg";

import KerebanganCommunity1 from "../../assets/Kerebangan/Community1.jpg";
import KerebanganCommunity2 from "../../assets/Kerebangan/Community2.jpg";

import RusuCommunity1 from "../../assets/Rusu/Community1.jpg";
import RusuCommunity2 from "../../assets/Rusu/Community2.jpg";

import TelinganCommunity1 from "../../assets/Telingan/Community1.jpg";
import TelinganCommunity2 from "../../assets/Telingan/Community2.jpg";

import UkatCommunity1 from "../../assets/Ukat/Community1.jpg";
import UkatCommunity2 from "../../assets/Ukat/Community2.jpg";

const KidingCommunityImages = [KidingCommunity1];
const PalunganCommunityImages = [PalunganCommunity1, PalunganCommunity2];
const KerebanganCommunityImages = [KerebanganCommunity1, KerebanganCommunity2];
const RusuCommunityImages = [RusuCommunity1, RusuCommunity2];
const TelinganCommunityImages = [TelinganCommunity1, TelinganCommunity2];
const UkatCommunityImages = [UkatCommunity1, UkatCommunity2];

const ProductInfo = (props) => {
  const {
    litSaraProduct,
    essentialOil,
    qcDocument,
    origin,
    handleScrollClickProductJourney,
    handleScrollClickSocialImpact,
  } = props;

  const [communityImage, setCommunityImage] = useState(null);
  const [productImage, setProductImage] = useState(null);

  useEffect(() => {
    if (origin && origin.name) {
      if (origin.name.toLowerCase().includes("lungan")) {
        const random = Math.floor(Math.random() * PalunganCommunityImages.length);
        setCommunityImage(PalunganCommunityImages[random]);
      } else if (origin.name.toLowerCase().includes("kiding")) {
        const random = Math.floor(Math.random() * KidingCommunityImages.length);
        setCommunityImage(KidingCommunityImages[random]);
      } else if (origin.name.toLowerCase().includes("telingan")) {
        const random = Math.floor(Math.random() * TelinganCommunityImages.length);
        setCommunityImage(TelinganCommunityImages[random]);
      } else if (origin.name.toLowerCase().includes("kerebangan")) {
        const random = Math.floor(Math.random() * KerebanganCommunityImages.length);
        setCommunityImage(KerebanganCommunityImages[random]);
      } else if (origin.name.toLowerCase().includes("rusu")) {
        const random = Math.floor(Math.random() * RusuCommunityImages.length);
        setCommunityImage(RusuCommunityImages[random]);
      } else if (origin.name.toLowerCase().includes("ukat")) {
        const random = Math.floor(Math.random() * UkatCommunityImages.length);
        setCommunityImage(UkatCommunityImages[random]);
      }
    }
  }, [origin]);

  useEffect(() => {
    if (litSaraProduct.batchNo) {
      if (litSaraProduct.batchNo.split("_")[0] === "LIT104-004") {
        setProductImage(HairConditioner);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT103-004") {
        setProductImage(BodyWash60ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT109-004") {
        setProductImage(NaturalShampoo60ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT102-004") {
        setProductImage(BodyOil60ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "NAT005-011") {
        setProductImage(WetWipe10s);
      } else if (litSaraProduct.batchNo.split("_")[0] === "NAT002-004") {
        setProductImage(BorneoBreezeBodyOil60ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT111-001") {
        setProductImage(PremiumMassageOil15ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT112-001") {
        setProductImage(BlissMassageOil15ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT113-001") {
        setProductImage(RefreshingMassageOil15ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT114-001") {
        setProductImage(SoothingMassageOil15ml);
      } else if (litSaraProduct.batchNo.split("_")[0] === "LIT115-001") {
        setProductImage(RelaxingMassageOil15ml);
      } else {
        setProductImage(LitSaraImage[litSaraProduct.batchNo.split("_")[0]]);
      }
    }
  }, [litSaraProduct]);
  return (
    <Card>
      <Box sx={{ textAlign: "center" }} pt={2}>
        <img src={LitSaraLogoHorizontal} height={"50px"} width="auto" alt="Traceability by Sinisana" />
      </Box>
      <CardMedia
        component="img"
        height="220px"
        src={productImage}
        alt={litSaraProduct.name + " " + litSaraProduct.batchNo}
        sx={{
          objectFit: "contain",
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="#473C33" fontWeight={500}>
          {litSaraProduct?.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2" pt={1.5}>
            Batch No:
          </Typography>
          <Typography gutterBottom variant="subtitle2" pt={1.5}>
            {litSaraProduct?.batchNo}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Production Date:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {litSaraProduct?.productionDate}
          </Typography>
        </Stack>

        {litSaraProduct?.expiryDate && (
          <Stack direction="row" justifyContent="space-between">
            <Typography gutterBottom variant="subtitle2">
              Expiry Date:
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {litSaraProduct?.expiryDate}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Producer:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {litSaraProduct?.location}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Raw Material:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            <i>Litsea cubeba</i>{" "}
            {essentialOil && essentialOil.name
              ? essentialOil?.name.split("-")[0].slice(-1) === "L"
                ? "Leaf Oil"
                : "Fruit Oil"
              : null}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle2">
            Harvest Location:
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {origin?.name}
          </Typography>
        </Stack>

        {qcDocument && qcDocument.length > 0 && (
          <Stack direction="row" justifyContent="space-between">
            <Typography gutterBottom variant="subtitle2">
              Quality Check:
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {qcDocument[0].date}
            </Typography>
          </Stack>
        )}

        {communityImage && (
          <Box my={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
            <CardMedia
              component="img"
              src={communityImage}
              alt="Image description"
              sx={{ height: 200, objectFit: "cover" }}
            />
          </Box>
        )}

        <Typography gutterBottom color="#483434" variant="subtitle2">
          With each purchase of our products, you're helping to support the livelihood and well-being of our community,
          while also making a{" "}
          <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleScrollClickSocialImpact}>
            positive impact
          </span>{" "}
          on society and supporting its growth.
        </Typography>

        <Stack spacing={2} direction={"row"} pt={1}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleScrollClickSocialImpact}
            sx={{
              color: "#224B0C",
              borderColor: "#224B0C",
              width: "50%",
            }}
          >
            See Your Impact
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleScrollClickProductJourney}
            sx={{
              color: "#224B0C",
              borderColor: "#224B0C",
              width: "50%",
            }}
          >
            Product Journey
          </Button>
        </Stack>
        <Box pt={1.5}>
          <Link href="https://www.litsara.com/index.php" underline="hover" color="#224B0C" variant="subtitle2">
            LitSara®: Borneo's Natural Personal Care - Home
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductInfo;
