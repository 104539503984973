export const timelineData = [
  {
    title: "Halal Abattoir",
    description:
      "The cattle is slaughtered and processed into beef cuts at a modern halal-certified facility with full refrigeration in the whole process.",
    date: "24 February 2022",
    details: [
      {
        title: "Halal Certification",
        data: "YES - View",
      },
      {
        title: "Sinisana Blockchain Verification",
        data: "29d342ef5bc95ff96ca6a947e9f2f9d754e71460b1e36b54657bea49f074b0aa",
      },
    ],
    imgSrc: "/images/abattoir.jpg",
  },
  {
    title: "Raw Beef Receiving",
    description:
      "Workers inspect the halal raw beef when it arrives at the processing plant to ensure there are no visible bones or foreign materials and that the beef does not have an off-odor. Our food safety experts will collect samples to test for the presence of bacteria and ensure that the beef's lean-to-fat ratio is correct.",
    date: "1 March 2022",
    details: [
      {
        title: "Raw Beef Receipt Date",
        data: "1 Mar 2022",
      },
      {
        title: "Lab Test Result",
        data: "Pass",
      },
      {
        title: "Sinisana Blockchain Verification",
        data: "ecc8bd44bf46bfa3b4f8100bc786154af361f8378f6e82ab0ea669b500b977c7",
      },
    ],
    imgSrc: "/images/receiving.jpg",
  },
  {
    title: "Patty Production",
    description:
      "The raw beef will be put through a grinding process to create an initial ground beef before being mixed with halal food additives such as salt and seasoning. The entire process is temperature-controlled to reduce the possibility of bacteria growth and to make it easier to shape the patties. Adjustments are made to ensure that each burger patty has a consistent taste and nutritional value.",
    date: "5 March 2022",
    imgSrc: "/images/production.jpg",
    details: [
      {
        title: "Production Date",
        data: "5 Mar 2022",
      },
      {
        title: "Sinisana Blockchain Verification",
        data: "8343c0319a189080e67eb2e0b3ab1061586b66d09f951dcf4a14c6396ec14078",
      },
    ],
  },
  {
    title: "Freezing",
    description:
      "Our burger patties are blast frozen for at least 120 minutes at -40°C for food safety and long-lasting preservation.",
    date: "5 March 2022",
    imgSrc: "/images/freezing.png",
    details: [
      {
        title: "Freezing Time",
        data: "82 minutes",
      },
      {
        title: "Average Freezer Temp",
        data: "-20.7°C",
      },
      {
        title: "Sinisana Blockchain Verification",
        data: "e5e2d70b81236d461bf58ef7c07b2422e7ba08daa000c47bee2bd1eec15e48b2",
      },
    ],
  },
  {
    title: "Packing",
    description:
      "Each burger patty is packed together into our iconic pack of 6 that you are holding in your hands now.",
    date: "6 March 2022",
    imgSrc: "/images/packing.png",
    details: [
      {
        title: "Batch No.",
        data: "0519328 L6",
      },
      {
        title: "Unique Code",
        data: "3c9a14eda5f",
      },
      {
        title: "Sinisana Blockchain Verification",
        data: "7bedf7664d09f0ef6cabe5117fe230c924a51103d3dae75515d3a84660ad03d2",
      },
    ],
  },
  {
    title: "Distribution",
    description:
      "The pack of burger patties are distributed to various distributors and dealers throughout Malaysia. Our cold chain distribution ensures that the patties are always kept at below -20°C.",
    date: "7 March 2022",
    imgSrc: "/images/distribution.jpg",
    details: [
      {
        title: "Destination",
        data: "Supreme Cold Storage Sdn. Bhd.",
      },
      {
        title: "Sinisana Blockchain Verification",
        data: "89b71bd9133fea60a6c865d36059762d6d55d259a254ef90031d66acdbadb502",
      },
    ],
  },
];
