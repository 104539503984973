import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { format, isValid } from "date-fns";

import ProductNamePanel from "./components/ProductNamePanel";
import ProductAttributePanel from "./components/ProductAttributePanel";
import ProductDescriptionPanel from "./components/ProductDescriptionPanel";

const backendUrl = "https://autentik.demos.sinisana.io/api/_1125";
const imageUrl = "https://autentik.demos.sinisana.io/api/w1206";

// const backendUrl = "http://localhost:4000/_1125";
// const imageUrl = "http://localhost:4000/w1206";

const OtentikTrace = () => {
  const traceCode = window.location.href;
  //   const traceCode = "https://trace.sinisana.io/trace?a=78ec17ce-66f9-4b94-b64e-1ccaa16697e6";

  const [searching, setSearching] = useState(true);

  const [templateData, setTemplateData] = useState({});
  const [qrCodeData, setQrCodeData] = useState({});
  const [productImageUrl, setProductImageUrl] = useState("");
  const [productPublicInfo, setProductPublicInfo] = useState({});
  const [productPhaseList, setProductPhaseList] = useState([]);
  const [productJourneyList, setProductJourneyList] = useState([]);

  useEffect(() => {
    fetch(`${backendUrl}/trace`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        traceCode: traceCode,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success" && res.data) {
          if (res.data.traceCode && res.data.traceCode.length > 0) {
            setQrCodeData(res.data.traceCode[0]);
          }
          if (res.data.template && res.data.template.length > 0) {
            setTemplateData(JSON.parse(res.data.template[0]["StoryTemplate/TemplateJSON"]));
          }
        }
      })
      .finally(() => {
        setSearching(false);
      });
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = templateData?.layoutBackgroundColor || "#f4f6f8";
    document.body.style.backgroundImage = templateData?.layoutBackgroundImageBase64
      ? `url(${templateData?.layoutBackgroundImageBase64})`
      : null;
    document.body.style.backgroundSize = templateData?.layoutBackgroundSize || null;

    if (qrCodeData?.Production && qrCodeData?.Production.length > 0) {
      const image = qrCodeData?.Production[0]?.["Product"]?.["Image"];

      try {
        const imageObj = JSON.parse(image);
        if (imageObj?.["key"]) {
          setProductImageUrl(imageUrl + "/images/" + imageObj["key"]);
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (qrCodeData?.Production && qrCodeData?.Production.length > 0) {
      try {
        const publicInfo = JSON.parse(qrCodeData?.Production[0]?.["Production/PublicInfo"]);
        setProductPublicInfo(publicInfo);
      } catch (err) {
        console.log(err);
      }

      if (qrCodeData?.Production[0]?.["Processes"]) {
        setProductJourneyList(qrCodeData?.Production[0]?.["Processes"]);
      }

      if (qrCodeData?.Production[0]?.["Product"]["Phases"]) {
        setProductPhaseList(qrCodeData?.Production[0]?.["Product"]["Phases"]);
      }
    }
  }, [templateData, qrCodeData]);

  const checking = (item) => {
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (iso8601Pattern.test(item)) {
      return format(new Date(item), "dd MMM, yyyy");
    } else {
      return item;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        {searching ? (
          <Box
            sx={{
              display: "flex",
              marginTop: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : templateData ? (
          <Stack spacing={2} paddingTop={4} paddingBottom={4}>
            <Card>
              <CardMedia
                component="img"
                height={templateData?.productImageHeight + "px"}
                src={productImageUrl}
                alt="Product Image"
                sx={{
                  objectFit: templateData?.productImageObjectFit,
                }}
              />

              <CardContent>
                <ProductNamePanel
                  fontSize={templateData?.productNameFontSize}
                  fontWeight={templateData?.productNameFontWeight}
                  color={templateData?.productNameColor}
                  marginTop={templateData?.productNameMarginTop}
                  marginBottom={templateData?.productNameMarginBottom}
                  textAlign={templateData?.productNameTextAlign}
                >
                  {qrCodeData?.Production && qrCodeData?.Production.length > 0
                    ? qrCodeData?.Production[0]?.["Product"]?.["Name"]
                    : ""}
                </ProductNamePanel>

                <Stack
                  spacing={Number(templateData?.productAttributeRowSpacing)}
                  marginTop={templateData?.productAttributeMarginTop + "px"}
                  marginBottom={templateData?.productAttributeMarginBottom + "px"}
                >
                  {Object.keys(productPublicInfo).map((key, index) => {
                    return (
                      <ProductAttributePanel
                        key={index}
                        fontSize={templateData?.productAttributeFontSize}
                        fontWeight={templateData?.productAttributeFontWeight}
                        color={templateData?.productAttributeColor}
                        label={key}
                        value={productPublicInfo[key]}
                      />
                    );
                  })}
                </Stack>

                <ProductDescriptionPanel
                  fontSize={templateData?.productDescriptionFontSize}
                  fontWeight={templateData?.productDescriptionFontWeight}
                  color={templateData?.productDescriptionColor}
                  marginTop={templateData?.productDescriptionMarginTop}
                  marginBottom={templateData?.productDescriptionMarginBottom}
                >
                  {qrCodeData?.Production &&
                    qrCodeData?.Production.length > 0 &&
                    qrCodeData?.Production[0]?.["Product"]?.["Description"]}
                </ProductDescriptionPanel>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography
                  sx={{
                    fontSize: templateData?.productJourneyFontSize + "px",
                    fontWeight: templateData?.productJourneyFontWeight,
                    color: templateData?.productJourneyColor,
                    marginTop: templateData?.productJourneyMarginTop + "px",
                    marginBottom: templateData?.productJourneyMarginBottom + "px",
                    textAlign: templateData?.productJourneyTextAlign,
                  }}
                >
                  {templateData?.productJourneyText}
                </Typography>

                <Stepper orientation="vertical">
                  {productJourneyList.map((journey, index) => {
                    let journeyDescription = "";

                    if (journey["Process/StoryDescription"]) {
                      const storyDescription = journey["Process/StoryDescription"];
                      journeyDescription = storyDescription;
                    } else {
                      const phase = productPhaseList.find(
                        (item) => item["ProductPhase/Name"] === journey["Process/Name"]
                      );

                      if (phase && phase["ProductPhase/StoryDescription"]) {
                        journeyDescription = phase["ProductPhase/StoryDescription"];

                        const publicInfo = journey["Process/PublicInfo"]
                          ? JSON.parse(journey["Process/PublicInfo"])
                          : {};

                        for (const key in publicInfo) {
                          const value = publicInfo[key];
                          journeyDescription = journeyDescription.replace(":::" + key + ":::", checking(value));
                        }
                      }
                    }

                    return (
                      <Step key={index} expanded>
                        <StepLabel
                          StepIconProps={{
                            style: {
                              color: templateData?.productJourneyTimelineTitleColor,
                            },
                          }}
                        >
                          <Typography
                            color={templateData?.productJourneyTimelineTitleColor}
                            fontSize={templateData?.productJourneyTimelineTitleFontSize + "px"}
                          >
                            {journey["Process/Name"]}
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          {journey["Process/ProcessTimestamp"] &&
                            isValid(new Date(journey["Process/ProcessTimestamp"])) && (
                              <Chip
                                sx={{
                                  fontSize: 10,
                                  marginBottom: 1,
                                }}
                                size="small"
                                label={format(new Date(journey["Process/ProcessTimestamp"]), "dd MMM, yyyy")}
                              />
                            )}
                          {journeyDescription ? (
                            <ProductDescriptionPanel
                              fontSize={templateData?.productJourneyTimelineDescriptionFontSize}
                              fontWeight={templateData?.productJourneyTimelineDescriptionFontWeight}
                              color={templateData?.productJourneyTimelineDescriptionColor}
                              marginTop={templateData?.productJourneyTimelineDescriptionMarginTop}
                              marginBottom={templateData?.productJourneyTimelineDescriptionMarginBottom}
                            >
                              {journeyDescription}
                            </ProductDescriptionPanel>
                          ) : (
                            journey["Process/PublicInfo"] &&
                            Object.keys(JSON.parse(journey["Process/PublicInfo"])).map((key, index) => {
                              const value = JSON.parse(journey["Process/PublicInfo"])[key];
                              return (
                                <ProductAttributePanel
                                  key={index}
                                  fontSize={templateData?.productJourneyTimelineDescriptionFontSize}
                                  color={templateData?.productJourneyTimelineDescriptionColor}
                                  label={key}
                                  value={value}
                                />
                              );
                            })
                          )}
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
              </CardContent>
            </Card>
          </Stack>
        ) : (
          <Typography variant="h6" sx={{ textAlign: "center", marginTop: 5 }}>
            Try again
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default OtentikTrace;
