import React, { useState, useEffect, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Timeline from "./Timeline/Timeline";
import ramlyBeefBurger from "./DinoPatty.png";

import map from "./BeefJourneyV3.gif";
//import map2 from "./Map_2.m4v";
import { gsap } from "gsap";
import { timelineData } from "./RamlyBurgerData";

const TraceMeBackView = () => {
  const headerImgEl = React.useRef(null);
  const titleEl = React.useRef(null);

  const [activeTab, setActiveTab] = useState("tab1");
  const [viewItem, setViewItem] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    //document.body.style.backgroundColor = "#424B54";
    document.body.style.height = "800px";
    document.body.style.backgroundImage = "linear-gradient(to bottom right, #edf6f9, #83c5be)";
    gsap.fromTo(headerImgEl.current, { y: -500 }, { duration: 1.5, ease: "bounce.out", y: 20 });
    gsap.fromTo(titleEl.current, { x: 1000 }, { duration: 0.5, ease: "power2.out", x: 0 });
  }, []);

  const handleClick = (item) => {
    setViewItem(item);
    setOpenDialog(true);
  };

  return (
    <Box sx={{ width: "100vw", height: "100vh" }}>
      <Container maxWidth="xxl" style={{ backgroundColor: "#FB8500", height: "10px" }}></Container>
      <Container maxWidth="xxl" style={{ backgroundColor: "#FFB703", height: "10px" }}></Container>
      <Box
        style={{
          height: "12rem",
          position: "relative",
          overflow: "hidden",
          backgroundImage: `url('https://static.wixstatic.com/media/78f342_c5b719d3d9534e5891606cc92bb3ab6e~mv2.jpg/v1/fill/w_560,h_372,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Black%20texture%20board.jpg')`,
          backgroundSize: "cover",
        }}
      >
        <Box
          ref={headerImgEl}
          sx={{
            position: "absolute",
            transform: "rotate(-10deg)",
            left: "20px",
          }}
        >
          <a href="/halal/burger">
            <img
              src={ramlyBeefBurger}
              alt="Halal Traceability by Sinisana"
              style={{ width: "200px", height: "auto" }}
            />
          </a>
        </Box>

        <Box
          ref={titleEl}
          sx={{
            position: "absolute",
            top: "30px",
            right: "20px",
            width: "8rem",
            color: "white",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: 24 }}>TRACE MY JOURNEY</h1>
        </Box>
      </Box>

      <Grid
        container
        spacing={2}
        padding={"16px"}
        style={{ textAlign: "center", fontWeight: 600, textTransform: "uppercase" }}
      >
        <Grid item xs={6}>
          <Box
            style={{
              color: activeTab === "tab1" ? "#FFB703" : "white",
              padding: 8,
              background: activeTab === "tab1" ? "#4d7e7b" : "#c6c6c6",
            }}
            onClick={() => {
              setActiveTab("tab1");
              document.body.style.height = "800px";
            }}
          >
            JOURNEY
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            style={{
              color: activeTab === "tab2" ? "#FFB703" : "white",
              padding: 8,
              background: activeTab === "tab2" ? "#4d7e7b" : "#c6c6c6",
            }}
            onClick={() => {
              setActiveTab("tab2");
              document.body.style.height = "2200px";
            }}
          >
            PRODUCTION
          </Box>
        </Grid>
      </Grid>

      {/* Tab 1 */}
      <Box hidden={activeTab !== "tab1"} padding={"16px"}>
        <img
          style={{
            borderRadius: 30,
            boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
          }}
          src={map}
          width={"100%"}
          height={"auto"}
          alt="Journey Map"
        />
        {/* <video width={"100%"} height={"auto"} controls>
          <source src={map2} type="video/m4v" />
        </video> */}
      </Box>

      {/* Tab 2 */}
      <Box hidden={activeTab !== "tab2"} padding={"16px"}>
        <Timeline animate={activeTab == "tab2"} items={timelineData} handleClick={handleClick} />
      </Box>

      <StoryDialog item={viewItem} handleClose={() => setOpenDialog(false)} open={openDialog} />
    </Box>
  );
};

export default TraceMeBackView;

const StoryDialog = ({ item, open, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <img src={item.imgSrc} height={"auto"} width="100%" />
      <DialogTitle>{item.title}</DialogTitle>
      <DialogContent>
        <List
          style={{
            overflow: "hidden",
            textAlign: "center",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {item?.details?.map((value) => {
            return (
              <ListItem disableGutters>
                <ListItemText primary={value.title} secondary={value.data} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};
