import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import SinisanaBlockchain from "../SinisanaBlockchain";
import beefBurger from "./DinoPatty.png";

const BurgerView = () => {
  useEffect(() => {
    // document.body.style.backgroundColor = "#219ebc";
    // document.body.style.backgroundImage = "linear-gradient(to bottom right, #219ebc, rgb(191,214,165))";
    document.body.style.backgroundImage = `url('https://static.wixstatic.com/media/78f342_c5b719d3d9534e5891606cc92bb3ab6e~mv2.jpg/v1/fill/w_560,h_372,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Black%20texture%20board.jpg')`;
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", height: "100vh" }}>
      <Container maxWidth="xxl" style={{ backgroundColor: "#FB8500", height: "10px" }}></Container>
      <Container maxWidth="xxl" style={{ backgroundColor: "#FFB703", height: "10px", marginBottom: 20 }}></Container>
      <Container maxWidth="sm" style={{ marginBottom: "30px" }}>
        <Box sx={{ textAlign: "center", margin: "30px 0px 5px", color: "white", fontSize: "1.5rem", fontWeight: 600 }}>
          YOUR PRODUCT'S DIGITAL ID
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 0px" }}>
          <img src={beefBurger} height="auto" width="50%" alt="Burger Traceability by Sinisana" />
        </Box>

        <Card sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
          <Box sx={{ textAlign: "center", color: "white", fontSize: "1.2rem", fontWeight: 500 }}>
            DINO BURGER PATTY (6 x 79g) - ORIGINAL
          </Box>

          <Box
            sx={{
              bgcolor: "#FFB703",
              width: "60%",
              margin: "20px auto",
              padding: "0.2rem 1.2rem",
              fontSize: "1.2rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            <a href="/halal/burger/traceMeBack" style={{ textDecoration: "none" }}>
              TRACE MY JOURNEY
            </a>
          </Box>

          <Box
            sx={{
              bgcolor: "#FB8500",
              width: "60%",
              margin: "20px auto",
              padding: "0.2rem 1.2rem",
              fontSize: "1.2rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            <a href="/halal/burger/halalcert" target="_blank" style={{ textDecoration: "none" }}>
              VIEW HALAL CERT
            </a>
          </Box>
          <Box
            sx={{
              bgcolor: "#fff",
              width: "60%",
              margin: "20px auto",
              padding: "0.2rem 1.2rem",
              fontSize: "1.2rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            BATCH 0519328 L6
          </Box>
        </Card>
      </Container>
      <SinisanaBlockchain />
    </Box>
  );
};

export default BurgerView;
