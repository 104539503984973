import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CollectLitSeaCubebaImage from "../assets/CollectLitSeaCubeba.jpg";
import HarvestImage from "../assets/Harvest.jpg";
import MountainImage from "../assets/Mountain.jpg";

const HarvestDialog = (props) => {
  const { visible, onClose } = props;

  return (
    <Dialog onClose={onClose} open={visible} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
      <DialogTitle sx={{ typography: "subtitle2", color: "#064420", fontSize: 20 }}>Harvest</DialogTitle>
      <DialogContent>
        {/* <Box pb={1.5} px={0.5}>
          <img
            src={MountainImage}
            alt="MountainImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box> */}

        {/* <Typography gutterBottom color="#064420" variant="body2">
          Nestled high up in the mountains at a whopping 412 meters above sea level, lies the charming community of
          Kampung Kiding. To reach their harvest location, one must embark on an adventurous hike that takes about an
          hour, meandering through lush bamboo bridges and mesmerizing waterfalls. It's an experience that's sure to
          leave your senses reeling with delight.
        </Typography>
        */}
        <Box py={1.5} px={0.5}>
          <img
            src={CollectLitSeaCubebaImage}
            alt="CollectLitSeaCubebaImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>
        <Typography gutterBottom color="#064420" variant="body2">
          Come harvest season, which runs from May to October, the entire community eagerly sets out on a quest to
          harvest the <i>Litsea cubeba</i> fruits and leaves by hand. The sweet fruits are handpicked only when they're
          perfectly ripe, while the tender leaves are harvested in their youthful prime. It's a labor of love that
          requires utmost dedication and patience.
        </Typography>
        <Box py={1.5} px={0.5}>
          <img
            src={HarvestImage}
            alt="CollectLitSeaCubebaImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>
        <Typography gutterBottom color="#064420" variant="body2">
          Once the harvest is complete, the fruits and leaves are carefully transported down from the mountain, ready to
          be processed into a wide range of delightful products.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
export default HarvestDialog;
