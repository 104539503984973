import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import ProductInfo from "./ScanResultComponents/ProductInfo";
// import SocialImpact from "./ScanResultComponents/SocialImpact";
// import ProductJourney from "./ScanResultComponents/ProductJourney";
import { format } from "date-fns";

const ScanResult = (props) => {
  const { result, historyResult } = props;

  // useEffect(() => {
  //   if (result) {

  //   }
  // }, [result]);

  return (
    <Stack spacing={2} paddingTop={4} paddingBottom={4}>
      <ProductInfo result={result} historyResult={historyResult} />
    </Stack>
  );
};

export default ScanResult;
