import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import QualityCheckImage from "../assets/QualityCheck.jpg";

const QualityCheckDialog = (props) => {
  const { visible, onClose } = props;

  return (
    <Dialog onClose={onClose} open={visible} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
      <DialogTitle>Quality Check</DialogTitle>
      <DialogContent>
        <Typography gutterBottom color="#064420" variant="body2">
          The Sarawak Biodiversity Center leaves no detail unchecked when it comes to ensuring the quality and purity of
          the <i>Litsea cubeba</i> essential oil. Their team of experts conducts a battery of rigorous tests that delve
          deep into the oil's chemical composition and biological activity. The evaluations are exhaustive and thorough,
          leaving no room for compromise.
        </Typography>
        <Box py={1.5} px={0.5}>
          <img
            src={QualityCheckImage}
            alt="QualityCheckImage"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: 8,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>

        <Typography gutterBottom color="#064420" variant="body2">
          These tests are a testament to the Center's unwavering commitment to excellence, as they strive to produce the
          finest products possible. By subjecting the <i>Litsea cubeba</i> essential oil to meticulous scrutiny, the
          Center confirms that it meets their exacting standards, ensuring that you receive nothing but the best.
          Therefore, you can rest assured that when you purchase this essential oil, you're investing in a product that
          has been tested and certified to be of the highest quality, providing you with an unforgettable experience.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
export default QualityCheckDialog;
