import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import StepConnector from "@mui/material/StepConnector";
import Typography from "@mui/material/Typography";
import LitSaraLogo from "./LitSaraLogoHorizontal.png";
import BackgroundImage1 from "./assets/bg-1.jpg";
import BackgroundImage2 from "./assets/bg-2.jpg";
import BackgroundImage3 from "./assets/bg-3.jpg";
import BackgroundImage4 from "./assets/bg-4.jpg";
import BackgroundImage5 from "./assets/bg-5.jpg";
import BackgroundImage6 from "./assets/bg-6.jpg";
import CommunityImage from "./assets/CommunityLady.jpg";
import CommunityImage2 from "./assets/smile.png";
import CollectLitSeaCubebaImage from "./assets/CollectLitSeaCubeba.jpg";
import HarvestImage from "./assets/Harvest.jpg";
import MountainImage from "./assets/Mountain.jpg";
import DistillationImage from "./assets/Distill.jpg";
import DistillationImage2 from "./assets/Distillation.jpg";
import QualityCheckImage from "./assets/QualityCheck.jpg";
import AllLitsaraProductImage from "./assets/Product.jpg";
import Search from "./components/Search";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoDialog from "./components/InfoDialog";
import { format, isValid, parse, subDays } from "date-fns";
import GroupsIcon from "@mui/icons-material/Groups";
import "./Litsara.css";

const backendUrl = "https://abs.sinisana.net/api/_1125";
// const backendUrl = "http://localhost:4000/_1125";

const BackgroundArray = [BackgroundImage1, BackgroundImage2, BackgroundImage3, BackgroundImage4, BackgroundImage5, BackgroundImage6];

const LitSaraSearch = () => {
  const [data, setData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedPackaging, setSelectedPackaging] = useState("");
  const [selectedBatchNo, setSelectedBatchNo] = useState("");
  const [result, setResult] = useState({});
  const [beneficiaryCount, setBeneficiaryCount] = useState(0);
  const [paymentData, setPaymentData] = useState({
    PaymentDate: "",
    Payer: "",
    Recipient: "",
  });

  const [editItem, setEditItem] = useState({});
  // const [infoDialog, setInfoDialog] = useState(false);
  const [harvestInfoDialog, setHarvestInfoDialog] = useState(false);
  const [distillInfoDialog, setDistillInfoDialog] = useState(false);
  const [qualityInfoDialog, setQualityInfoDialog] = useState(false);
  const [productionInfoDialog, setProductionInfoDialog] = useState(false);

  const [originId, setOriginId] = useState(null);
  const [harvestStory, setHarvestStory] = useState({});
  const [distillationStory, setDistillationStory] = useState({});
  const [qualityControlStory, setQualityControlStory] = useState({});
  const [productionStory, setProductionStory] = useState({});
  const [documents, setDocuments] = useState({});
  const [latestDistillation, setLatestDistillation] = useState({});
  const [productionDate, setProductionDate] = useState("");
  const [harvestDate, setHarvestDate] = useState("");

  useEffect(() => {
    const random = Math.floor(Math.random() * 5);
    document.body.style.backgroundImage = `url(${BackgroundArray[random]})`;
    document.body.style.backgroundSize = "cover";
    // document.body.style.backgroundImage = "linear-gradient(to bottom right, #edf6f9, #83c5be)";
  }, []);

  useEffect(() => {
    // Obtain product options from Backend
    fetch(`${backendUrl}/options?type=Litsara Product`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === "success") {
          let object = {};

          for (const item of res.data) {
            if (object.hasOwnProperty(item.attributes["Product"])) {
              const product = item.attributes["Product"];

              if (!object[product].hasOwnProperty(item.attributes["Packaging"])) {
                object[product][item.attributes["Packaging"]] = [item.attributes["Image URL"]];
              }
            } else {
              object[item.attributes["Product"]] = {
                [item.attributes["Packaging"]]: item.attributes["Image URL"],
              };
            }
          }

          setData(object);
        }
      });
  }, []);

  useEffect(() => {
    if (result && result["Product/Name"]) {
      // If result has a traceable origin, set originId and pull stories from backend
      if (result["TraceableBOM"] && result["TraceableBOM"][0]["StockTransfer"] && result["TraceableBOM"][0]["StockTransfer"][0]["TraceableOrigin"]) {
        setOriginId(result["TraceableBOM"][0]["StockTransfer"][0]["TraceableOrigin"]["_id"]);
      }

      // Extract Payment information from result
      if (
        result["TraceableBOM"] &&
        result["TraceableBOM"][0]["StockTransfer"] &&
        result["TraceableBOM"][0]["StockTransfer"][0]["Payment"] &&
        result["TraceableBOM"][0]["StockTransfer"][0]["Payment"][0]
      ) {
        const payment = result["TraceableBOM"][0]["StockTransfer"][0]["Payment"][0];
        setPaymentData({
          PaymentDate: payment["PaymentDate"],
          Payer: payment["Payer"]["Name"],
          Recipient: payment["Recipient"]["Name"],
        });
      }

      let beneficiarySet = new Set(); // to prevent duplicate beneficiaries

      // If result has a beneficiary, set beneficiaryCount
      if (result["TraceableBOM"] && result["TraceableBOM"].length > 0) {
        // Each traceableBOM is a distilled bottle of Essential Oil
        for (const essentialOilBottle of result["TraceableBOM"]) {
          // Each essential oil bottle has a list of distillation processes...
          if (essentialOilBottle["Processes"] && essentialOilBottle["Processes"].length > 0) {
            // A process can be a distillation, quality control, or production process
            for (const process of essentialOilBottle["Processes"]) {
              if (process["Name"] === "Distillation") {
                const distillationInfo = JSON.parse(process["PrivateInfo"]);
                beneficiarySet.add(distillationInfo["Distilled By"]);
              }
            }
          }
        }
      }

      setBeneficiaryCount(beneficiarySet.size);

      //  Retrieve the most recent distillation date of the first bottle
      if (result["TraceableBOM"] && result["TraceableBOM"][0]["Processes"] && result["TraceableBOM"][0]["Processes"].length > 0) {
        const latestItem = result["TraceableBOM"][0]["Processes"].reduce((latest, item) => {
          if (item["Name"] === "Distillation") {
            if (new Date(item.ProcessDate) instanceof Date && (latest === null || new Date(item.ProcessDate) > new Date(latest.ProcessDate))) {
              return item;
            }
            return latest;
          }
          return latest;
        }, null);

        if (latestItem) {
          let item = {
            origin: latestItem["Location"],
            date: format(new Date(latestItem["ProcessDate"]), "MMM dd yyyy"),
          };
          const harvestDay = subDays(new Date(latestItem["ProcessDate"]), 1);
          const harvestDayString = format(harvestDay, "MMM dd yyyy");

          setLatestDistillation(item);
          setHarvestDate(harvestDayString);
        }
      }

      // Get the document information
      if (result["TraceableBOM"] && result["TraceableBOM"][0]["Documents"]) {
        for (const document of result["TraceableBOM"][0]["Documents"]) {
          let documentTypes = document.Tags.includes("Quality Control");
          if (documentTypes) {
            let item = {
              name: document["Creator"]["Name"],
              date: format(new Date(document["UploadTimestamp"]), "MMM dd yyyy"),
            };
            setDocuments(item);
          }
        }
      }

      const regex = /\d+/g;
      const match = selectedBatchNo.match(regex);
      const date = parse(match[0], "ddMMyyyy", new Date());
      if (isValid(date)) {
        setProductionDate(format(date, "MMM dd yyyy"));
      }
    } else {
      setOriginId(null);
    }
  }, [result]);

  useEffect(() => {
    if (originId) {
      fetch(`${backendUrl}/entities/${originId}`)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.status === "success") {
            const array = res.data;

            const storyByTag = {};

            for (const item of array) {
              for (const tag of item.tags) {
                storyByTag[tag] = [...(storyByTag[tag] || []), item];
              }
            }

            // randomly assign a story if more than one
            for (const key in storyByTag) {
              if (storyByTag[key].length > 1) {
                const random = Math.floor(Math.random() * storyByTag[key].length);
                storyByTag[key] = storyByTag[key][random];
              } else {
                storyByTag[key] = storyByTag[key][0];
              }
            }

            setHarvestStory(storyByTag["Harvest"]);
            setDistillationStory(storyByTag["Distillation"]);
            setQualityControlStory(storyByTag["Quality Control"]);
            setProductionStory(storyByTag["Production"]);
          }
        });
    }
  }, [originId]);

  // useEffect(() => {
  //   console.log("harvestStory", harvestStory);
  //   console.log("distillationStory", distillationStory);
  //   console.log("qualityControlStory", qualityControlStory);
  //   console.log("productionStory", productionStory);
  // }, [harvestStory, distillationStory, qualityControlStory, productionStory]);

  const handleChangeProduct = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleChangePackaging = (event) => {
    setSelectedPackaging(event.target.value);
  };

  const handleChangeBatchNo = (event) => {
    setSelectedBatchNo(event.target.value);
  };

  const handleSubmit = () => {
    const params = {
      product: selectedProduct,
      packaging: selectedPackaging,
      batchNo: selectedBatchNo,
    };

    fetch(`${backendUrl}/trace`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === "success") {
          if (res.data) {
            setResult(res.data);
          } else {
            setResult({
              Product: "Not Found",
            });
          }
        } else {
          setResult({
            Product: "Not Found",
          });
        }
      });
  };

  const handleScrollClickSocialImpact = () => {
    const titleElement = document.getElementById("socialImpact");
    if (titleElement) {
      window.scrollTo({
        top: titleElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleScrollClickProductJourney = () => {
    const titleElement = document.getElementById("productJourney");
    if (titleElement) {
      window.scrollTo({
        top: titleElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 70;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100vw", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        {result && result.Product && result.Product === "Not Found" ? (
          <Stack paddingTop={8}>
            <Card style={{ backgroundColor: "#FAF2DA" }}>
              <CardContent>
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <Typography gutterBottom variant="overline" color="#395144" fontSize={20} fontWeight="bold">
                    Product Not Found
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      bgcolor: "#1C6758",
                      textAlign: "center",
                      color: "#fffde7",
                      ":hover": {
                        bgcolor: "#125447",
                      },
                    }}
                    onClick={() => {
                      setResult({});
                    }}
                  >
                    Search Again
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        ) : JSON.stringify(result) !== "{}" ? (
          <Stack spacing={2} paddingTop={4} paddingBottom={4}>
            <Card>
              <Box sx={{ textAlign: "center" }} pt={2}>
                <img src={LitSaraLogo} height={"50px"} width="auto" alt="Traceability by Sinisana" />
              </Box>
              <CardMedia
                component="img"
                height="220px"
                src={data[selectedProduct][selectedPackaging]}
                alt={selectedProduct ? selectedProduct : "Product Image"}
                sx={{
                  objectFit: "contain",
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color="#473C33" fontWeight={500}>
                  {result["Product/Name"]}
                </Typography>

                <Typography gutterBottom color="#483434" variant="subtitle2" pt={1.5}>
                  Batch No: <strong>{selectedBatchNo}</strong>
                </Typography>

                {productionDate && (
                  <span>
                    <Typography gutterBottom color="#483434" variant="subtitle2">
                      Production Date: <strong>{productionDate}</strong>
                    </Typography>
                  </span>
                )}

                <Typography gutterBottom color="#483434" variant="subtitle2">
                  Producer: <strong>{result["Product/Location"]}</strong>
                </Typography>

                <Typography gutterBottom color="#483434" variant="subtitle2">
                  Raw Materials Used: <strong>Litsea Cubeba Fruit & Leaf Oil</strong>
                </Typography>

                <Typography gutterBottom color="#483434" variant="subtitle2">
                  Harvest Location: <strong>{result["TraceableBOM"][0]["StockTransfer"][0]["TraceableOrigin"]["Name"]}</strong>
                </Typography>

                {Object.keys(documents).length !== 0 && (
                  <Typography color="#483434" variant="subtitle2">
                    Quality Check: <strong>{documents.date}</strong>
                  </Typography>
                )}
                <Box my={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
                  <CardMedia component="img" src={CommunityImage} alt="Image description" sx={{ height: 200, objectFit: "cover" }} />
                </Box>
                {/* <CardMedia
                  component="img"
                  height="220px"
                  src={CommunityImage}
                  alt={"Community Image"}
                  sx={{
                    objectFit: "contain",
                  }}
                /> */}
                <Typography gutterBottom color="#483434" variant="subtitle2">
                  {/* Each purchase of our goods contributes to the livelihood and well-being of the community, making a{" "}
                  <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleScrollClickSocialImpact}>
                    positive impact
                  </span>{" "}
                  on society and supporting its growth. */}
                  With each purchase of our products, you're helping to support the livelihood and well-being of our community, while also making a{" "}
                  <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleScrollClickSocialImpact}>
                    positive impact
                  </span>{" "}
                  on society and supporting its growth.
                </Typography>

                <Stack spacing={2} direction={"row"} pt={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleScrollClickSocialImpact}
                    sx={{
                      color: "#224B0C",
                      borderColor: "#224B0C",
                      width: "50%",
                    }}
                  >
                    See Your Impact
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleScrollClickProductJourney}
                    sx={{
                      color: "#224B0C",
                      borderColor: "#224B0C",
                      width: "50%",
                    }}
                  >
                    Product Journey
                  </Button>
                </Stack>
                <Box pt={1.5}>
                  <Link href="https://www.litsara.com/index.php" underline="hover" color="#224B0C" variant="subtitle2">
                    LitSara®: Borneo's Natural Personal Care - Home
                  </Link>
                </Box>
              </CardContent>
            </Card>

            <div class="reveal" id="socialImpact">
              <Card style={{ backgroundColor: "#FAF2DA" }}>
                <CardContent>
                  <Typography gutterBottom variant="overline" color="#395144" fontSize={20} fontWeight="bold">
                    Social Impact
                  </Typography>
                  <div class="reveal">
                    <Box mb={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
                      <CardMedia component="img" src={CommunityImage2} alt="Image description" sx={{ height: 200, objectFit: "cover" }} />
                    </Box>
                  </div>

                  {/* <div class="reveal">
                    <Typography gutterBottom color="#395144" variant="body2">
                      <strong>Sarawak Biodiversity Center (SBC)</strong> has <strong>Access and Benefit Sharing (ABS)</strong>. This is to ensure that
                      the access and use of biological resources will result in fair benefits for users, providers, the ecosystem, and communities.
                    </Typography>
                  </div> */}

                  {beneficiaryCount > 0 && (
                    <div class="reveal">
                      <Stack direction={"row"} spacing={2} alignItems={"center"} pb={1}>
                        <Box sx={{ borderRadius: "50%", border: 1, borderColor: "#395144", px: 0.7, py: 0.3 }}>
                          <GroupsIcon sx={{ color: "#395144" }} />
                        </Box>
                        <Typography color="#395144" variant="body2">
                          A total of{" "}
                          {beneficiaryCount > 1 ? (
                            <span>
                              <strong>{beneficiaryCount} families</strong> were
                            </span>
                          ) : beneficiaryCount === 1 ? (
                            <span>
                              <strong>{beneficiaryCount} family</strong> was
                            </span>
                          ) : null}{" "}
                          involved in distilling the raw material used for this product
                          {Object.keys(latestDistillation).length !== 0 ? (
                            <span>
                              {" "}
                              and the raw material was <strong>distilled</strong> on <strong>{latestDistillation.date}</strong>, at{" "}
                              <strong>{latestDistillation.origin}</strong>.
                            </span>
                          ) : (
                            <span>.</span>
                          )}
                        </Typography>
                      </Stack>
                    </div>
                  )}

                  {paymentData["PaymentDate"] && paymentData["Payer"] && paymentData["Recipient"] ? (
                    <div class="reveal">
                      <Typography gutterBottom color="#395144" variant="body2">
                        On <strong>{format(new Date(paymentData["PaymentDate"]), "MMM dd yyyy")}</strong>, <strong>{paymentData["Payer"]}</strong>{" "}
                        paid <strong>{paymentData["Recipient"]}</strong> for the raw ingredient harvested from the community.
                      </Typography>
                    </div>
                  ) : null}

                  <div class="reveal">
                    <Typography gutterBottom color="#395144" variant="body2">
                      Thank you for supporting the livelihood of the community.
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div class="reveal" id="productJourney">
              <Card style={{ backgroundColor: "#473C33" }}>
                <CardContent>
                  <Typography gutterBottom variant="overline" color="#FAF2DA" fontSize={20} fontWeight="bold" textDecoration="underline">
                    Product Journey
                  </Typography>
                  <Stepper orientation="vertical">
                    {/* {JSON.stringify(harvestStory) !== "{}" && ( */}
                    <Step active>
                      <div class="reveal">
                        <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                          <CardContent>
                            <StepLabel
                              StepIconProps={{
                                style: {
                                  color: "#064420",
                                },
                              }}
                            >
                              {/* {harvestStory?.title} */}
                              Harvest
                            </StepLabel>
                            <StepContent>
                              <Stack direction="row" justifyContent={"space-between"}>
                                {/* {harvestStory?.shortDescription ?? "No description"} */}
                                {harvestDate !== "" ? (
                                  <Typography color="#064420" variant="body2">
                                    On <strong>{harvestDate}</strong> , the community made their way to the mountain for a hike to collect Litsea
                                    cubeba fruits and leaves for harvest.
                                  </Typography>
                                ) : (
                                  <Typography color="#064420" variant="body2">
                                    The community members travel to the mountain to harvest the Litsea cubeba fruits and leaves.
                                  </Typography>
                                )}
                                <IconButton
                                  onClick={() => {
                                    setEditItem(harvestStory);
                                    // setInfoDialog(true);
                                    setHarvestInfoDialog(true);
                                  }}
                                >
                                  <ArrowForwardIosIcon fontSize={"small"} />
                                </IconButton>
                              </Stack>
                            </StepContent>
                          </CardContent>
                        </Card>
                      </div>
                    </Step>
                    {/* )} */}

                    {/* {JSON.stringify(distillationStory) !== "{}" && ( */}
                    <Step active>
                      <div class="reveal">
                        <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                          <CardContent>
                            <StepLabel
                              StepIconProps={{
                                style: {
                                  color: "#064420",
                                },
                              }}
                            >
                              Distillation
                              {/* {distillationStory?.title} */}
                            </StepLabel>
                            <StepContent>
                              <Stack direction="row" justifyContent={"space-between"}>
                                {Object.keys(latestDistillation).length !== 0 ? (
                                  <Typography color="#064420" variant="body2">
                                    The harvested fruits and leaves were distilled on <strong>{latestDistillation.date}</strong> to extract their
                                    essential oil.
                                  </Typography>
                                ) : (
                                  <Typography color="#064420" variant="body2">
                                    The fruits and leaves were distilled either on the same day or the next day to extract the essential oil.
                                  </Typography>
                                )}
                                <IconButton
                                  onClick={() => {
                                    setEditItem(distillationStory);
                                    // setInfoDialog(true);
                                    setDistillInfoDialog(true);
                                  }}
                                >
                                  <ArrowForwardIosIcon fontSize={"small"} />
                                </IconButton>
                              </Stack>
                            </StepContent>
                          </CardContent>
                        </Card>
                      </div>
                    </Step>
                    {/* )} */}

                    {/* {JSON.stringify(qualityControlStory) !== "{}" && ( */}
                    <Step active>
                      <div class="reveal">
                        <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                          <CardContent>
                            <StepLabel
                              StepIconProps={{
                                style: {
                                  color: "#064420",
                                },
                              }}
                            >
                              {/* {qualityControlStory?.title} */}
                              Quality Check
                            </StepLabel>
                            <StepContent>
                              <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <Typography color="#064420" variant="body2">
                                  {/* {qualityControlStory?.shortDescription ?? "No description"} */}
                                  The essential oil underwent quality and purity testing to ensure it met the necessary standards.
                                </Typography>
                                <IconButton
                                  onClick={() => {
                                    setEditItem(qualityControlStory);
                                    // setInfoDialog(true);
                                    setQualityInfoDialog(true);
                                  }}
                                >
                                  <ArrowForwardIosIcon fontSize={"small"} />
                                </IconButton>
                              </Stack>
                            </StepContent>
                          </CardContent>
                        </Card>
                      </div>
                    </Step>
                    {/* )} */}

                    {/* {JSON.stringify(productionStory) !== "{}" && ( */}
                    <Step active>
                      <div class="reveal">
                        <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                          <CardContent>
                            <StepLabel
                              StepIconProps={{
                                style: {
                                  color: "#064420",
                                },
                              }}
                            >
                              {/* {productionStory?.title} */}
                              Production
                            </StepLabel>
                            <StepContent>
                              <Stack direction="row" justifyContent={"space-between"}>
                                {/* {productionStory?.shortDescription ?? "No description"} */}
                                {productionDate !== "" ? (
                                  <Typography color="#064420" variant="body2">
                                    On <strong>{productionDate}</strong>, the essential oil was blended with other ingredients to produce the product
                                    you are holding now, <strong>{selectedProduct}</strong>.
                                  </Typography>
                                ) : (
                                  <Typography color="#064420" variant="body2">
                                    The essential oil was blended with other ingredients to produce the product you are holding now,{" "}
                                    <strong>{selectedProduct}</strong>. .
                                  </Typography>
                                )}
                                <IconButton
                                  onClick={() => {
                                    setEditItem(productionStory);
                                    // setInfoDialog(true);
                                    setProductionInfoDialog(true);
                                  }}
                                >
                                  <ArrowForwardIosIcon fontSize={"small"} />
                                </IconButton>
                              </Stack>
                            </StepContent>
                          </CardContent>
                        </Card>
                      </div>
                    </Step>
                    {/* )} */}
                  </Stepper>
                </CardContent>
              </Card>
            </div>

            <Button
              variant="contained"
              style={{ marginTop: "40px" }}
              fullWidth
              sx={{
                bgcolor: "#1C6758",
                textAlign: "center",
                color: "#fffde7",
                ":hover": {
                  bgcolor: "#125447",
                },
              }}
              onClick={() => {
                setResult({});
              }}
            >
              Search Again
            </Button>
          </Stack>
        ) : (
          <Search
            data={data}
            selectedProduct={selectedProduct}
            selectedPackaging={selectedPackaging}
            selectedBatchNo={selectedBatchNo}
            handleChangeProduct={handleChangeProduct}
            handleChangePackaging={handleChangePackaging}
            handleChangeBatchNo={handleChangeBatchNo}
            handleSubmit={handleSubmit}
          />
        )}
      </Container>

      {/* <InfoDialog item={editItem} visible={infoDialog} onClose={() => setInfoDialog(false)} image={BackgroundImage1} /> */}
      <Dialog onClose={() => setHarvestInfoDialog(false)} open={harvestInfoDialog} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
        <DialogTitle sx={{ typography: "subtitle2", color: "#064420", fontSize: 20 }}>Harvest</DialogTitle>
        <DialogContent>
          <Box pb={1.5} px={0.5}>
            <img
              src={MountainImage}
              alt="MountainImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>

          <Typography gutterBottom color="#064420" variant="body2">
            Nestled high up in the mountains at a whopping 412 meters above sea level, lies the charming community of Kampung Kiding. To reach their
            harvest location, one must embark on an adventurous hike that takes about an hour, meandering through lush bamboo bridges and mesmerizing
            waterfalls. It's an experience that's sure to leave your senses reeling with delight.
          </Typography>
          <Box py={1.5} px={0.5}>
            <img
              src={CollectLitSeaCubebaImage}
              alt="CollectLitSeaCubebaImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>
          <Typography gutterBottom color="#064420" variant="body2">
            Come harvest season, which runs from May to October, the entire community eagerly sets out on a quest to harvest the Litsea Cubeba fruits
            and leaves by hand. The sweet fruits are handpicked only when they're perfectly ripe, while the tender leaves are harvested in their
            youthful prime. It's a labor of love that requires utmost dedication and patience.
          </Typography>
          <Box py={1.5} px={0.5}>
            <img
              src={HarvestImage}
              alt="CollectLitSeaCubebaImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>
          <Typography gutterBottom color="#064420" variant="body2">
            Once the harvest is complete, the fruits and leaves are carefully transported down from the mountain, ready to be processed into a wide
            range of delightful products.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog onClose={() => setDistillInfoDialog(false)} open={distillInfoDialog} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
        <DialogTitle>Distillation</DialogTitle>
        <DialogContent>
          <Box pb={1.5} px={0.5}>
            <img
              src={DistillationImage}
              alt="DistillationImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>

          <Typography gutterBottom color="#064420" variant="body2">
            The essential oil extracted from the Litsea Cubeba fruits and leaves is a true labor of love that involves a meticulous 3-hour
            distillation process. The process takes place in a small community distillery that's powered by the raw passion of the local artisans.
            It's a sight to behold, as the expert craftsmen use their time-honored techniques to extract the purest essence from the bountiful
            harvest.
          </Typography>
          <Box py={1.5} px={0.5}>
            <img
              src={DistillationImage2}
              alt="DistillationImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>
          <Typography gutterBottom color="#064420" variant="body2">
            Once the distillation process is complete, the precious essential oil is stored in a designated bottle, carefully labeled and made
            available for transfer to the Sarawak Biodiversity Center. It's a moment of immense pride for the local community, as they witness their
            hard work and dedication transform into a tangible product that's valued by people around the world. So, whether you're looking for a
            unique and aromatic oil for your beauty routine or seeking a natural remedy for your ailments, this essential oil is sure to captivate
            your senses and leave you with a lasting impression.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog onClose={() => setQualityInfoDialog(false)} open={qualityInfoDialog} fullWidth PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}>
        <DialogTitle>Quality Check</DialogTitle>
        <DialogContent>
          <Typography gutterBottom color="#064420" variant="body2">
            The Sarawak Biodiversity Center leaves no detail unchecked when it comes to ensuring the quality and purity of the Litsea Cubeba essential
            oil. Their team of experts conducts a battery of rigorous tests that delve deep into the oil's chemical composition and biological
            activity. The evaluations are exhaustive and thorough, leaving no room for compromise.
          </Typography>
          <Box py={1.5} px={0.5}>
            <img
              src={QualityCheckImage}
              alt="QualityCheckImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>

          <Typography gutterBottom color="#064420" variant="body2">
            These tests are a testament to the Center's unwavering commitment to excellence, as they strive to produce the finest products possible.
            By subjecting the Litsea Cubeba essential oil to meticulous scrutiny, the Center confirms that it meets their exacting standards, ensuring
            that you receive nothing but the best. Therefore, you can rest assured that when you purchase this essential oil, you're investing in a
            product that has been tested and certified to be of the highest quality, providing you with an unforgettable experience.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setProductionInfoDialog(false)}
        open={productionInfoDialog}
        fullWidth
        PaperProps={{ style: { backgroundColor: "#E4EFE7" } }}
      >
        <DialogTitle>Production</DialogTitle>
        <DialogContent>
          <Typography gutterBottom color="#064420" variant="body2">
            The Litsea Cubeba essential oil is a key ingredient in a variety of products that are revered for their natural and therapeutic
            properties. To create the final product, the essential oil is blended with carefully chosen ingredients that complement its unique aroma
            and benefits. It's a process that requires finesse and a keen eye for detail, ensuring that the end result is nothing short of
            extraordinary.
          </Typography>
          <Box py={1.5} px={0.5}>
            <img
              src={AllLitsaraProductImage}
              alt="AllLitsaraProductImage"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 8,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>

          <Typography gutterBottom color="#064420" variant="body2">
            Once the ingredients are blended to perfection, the product is packaged and labeled, ready to be sold in the market. The packaging is just
            as thoughtful and deliberate as the blending process, with attention paid to every detail, from the label to the container. It's a
            testament to the care and passion that goes into every step of the process, leaving you with a product that's as visually stunning as it
            is effective. So, whether you're looking to pamper yourself or seeking a natural remedy, we hope it leaves you with a smile on your face.
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LitSaraSearch;
