import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format, isValid, subDays } from "date-fns";
import HarvestDialog from "../HarvestDialog";
import DistillationDialog from "../DistillationDialog";
import QualityCheckDialog from "../QualityCheckDialog";
import ProductionDialog from "../ProductionDialog";

const ProductJourney = (props) => {
  const { origin, litSaraProduct, distillationData } = props;

  const [harvestDate, setHarvestDate] = useState(null);
  const [lastDistillation, setLastDistillation] = useState(null);

  const [harvestDialog, setHarvestDialog] = useState(false);
  const [distillationDialog, setDistillationDialog] = useState(false);
  const [qualityCheckDialog, setQualityCheckDialog] = useState(false);
  const [productionDialog, setProductionDialog] = useState(false);
  const [skuNo, setSkuNo] = useState("");

  useEffect(() => {
    if (distillationData && distillationData.length > 0) {
      setLastDistillation({
        date: format(new Date(distillationData[0]["ProcessTimestamp"]), "MMM dd, yyyy"),
      });
      const lastDistillationDate = new Date(distillationData[0]["ProcessTimestamp"]);
      const harvestDay = subDays(lastDistillationDate, 1);
      setHarvestDate(format(harvestDay, "MMM dd, yyyy"));
    }
  }, [distillationData]);

  useEffect(() => {
    if (litSaraProduct && litSaraProduct.batchNo) {
      setSkuNo(litSaraProduct.batchNo.split("_")[0]);
    }
  }, [litSaraProduct]);

  return (
    <div class="reveal" id="productJourney">
      <Card style={{ backgroundColor: "#473C33" }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="overline"
            color="#FAF2DA"
            fontSize={20}
            fontWeight="bold"
            textDecoration="underline"
          >
            Product Journey
          </Typography>
          <Stepper orientation="vertical">
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Harvest
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"}>
                        {harvestDate !== "" ? (
                          <Typography color="#064420" variant="body2">
                            On <strong>{harvestDate}</strong> , the community made their way to the mountain for a hike
                            to collect <i>Litsea cubeba</i> fruits and leaves for harvest.
                          </Typography>
                        ) : (
                          <Typography color="#064420" variant="body2">
                            The community members travel to the mountain to harvest the <i>Litsea cubeba</i> fruits and
                            leaves.
                          </Typography>
                        )}
                        <IconButton
                          onClick={() => {
                            setHarvestDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton>
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Distillation
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"}>
                        {lastDistillation && lastDistillation.date ? (
                          <Typography color="#064420" variant="body2">
                            The harvested fruits and leaves were distilled on <strong>{lastDistillation.date}</strong>{" "}
                            to extract their essential oil.
                          </Typography>
                        ) : (
                          <Typography color="#064420" variant="body2">
                            The fruits and leaves were distilled either on the same day or the next day to extract the
                            essential oil.
                          </Typography>
                        )}
                        <IconButton
                          onClick={() => {
                            setDistillationDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton>
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Quality Check
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                        <Typography color="#064420" variant="body2">
                          The essential oil underwent quality and purity testing to ensure it met the necessary
                          standards.
                        </Typography>
                        <IconButton
                          onClick={() => {
                            setQualityCheckDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton>
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
            <Step active>
              <div class="reveal">
                <Card style={{ backgroundColor: "#E4EFE7", border: "2px #132a13 solid" }}>
                  <CardContent>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#064420",
                        },
                      }}
                    >
                      Production
                    </StepLabel>
                    <StepContent>
                      <Stack direction="row" justifyContent={"space-between"}>
                        {litSaraProduct ? (
                          skuNo === "LIT105-001" ||
                          skuNo === "LIT106-001" ||
                          skuNo === "LIT106-010" ||
                          skuNo === "LIT105-001A" ||
                          skuNo === "LIT105-010" ? (
                            isValid(new Date(litSaraProduct.productionDate)) ? (
                              <Typography color="#064420" variant="body2">
                                On <strong>{format(new Date(litSaraProduct.productionDate), "MMM dd, yyyy")}</strong>,
                                the essential oil was bottled to produce the product you are holding now,{" "}
                                <strong>{litSaraProduct.name}</strong>.
                              </Typography>
                            ) : (
                              <Typography color="#064420" variant="body2">
                                The <strong>{litSaraProduct.name}</strong> was skillfully produced, capturing its pure
                                essence without additional ingredient blending, ensuring the natural and unadulterated
                                quality of the product you now hold.
                              </Typography>
                            )
                          ) : isValid(new Date(litSaraProduct.productionDate)) ? (
                            <Typography color="#064420" variant="body2">
                              On <strong>{format(new Date(litSaraProduct.productionDate), "MMM dd, yyyy")}</strong>, the
                              essential oil was blended with other ingredients to produce the product you are holding
                              now, <strong>{litSaraProduct.name}</strong>.
                            </Typography>
                          ) : (
                            <Typography color="#064420" variant="body2">
                              The essential oil was blended with other ingredients to produce the product you are
                              holding now, <strong>{litSaraProduct.name}</strong>.
                            </Typography>
                          )
                        ) : (
                          <Typography color="#064420" variant="body2">
                            Product not found
                          </Typography>
                        )}

                        <IconButton
                          onClick={() => {
                            setProductionDialog(true);
                          }}
                        >
                          <ArrowForwardIosIcon fontSize={"small"} />
                        </IconButton>
                      </Stack>
                    </StepContent>
                  </CardContent>
                </Card>
              </div>
            </Step>
          </Stepper>
        </CardContent>
      </Card>

      <HarvestDialog origin={origin} visible={harvestDialog} onClose={() => setHarvestDialog(false)} />

      <DistillationDialog origin={origin} visible={distillationDialog} onClose={() => setDistillationDialog(false)} />

      <QualityCheckDialog origin={origin} visible={qualityCheckDialog} onClose={() => setQualityCheckDialog(false)} />

      <ProductionDialog origin={origin} visible={productionDialog} onClose={() => setProductionDialog(false)} />
    </div>
  );
};

export default ProductJourney;
